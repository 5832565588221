import { ReactElement, useEffect, useState } from "react";
import PrivateLayout from "../../components/layout/PrivateLayout";
import RoundedBox from "../../components/layout/RoundedBox";
import { Case, currency, Filing, makeName, Party } from "../../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import useFilingCases from "../../hooks/useFilingCases";
import FormLabel from "../../components/forms/FormLabel";
import { FormButton } from "../../components/forms/FormButton";
import BackArrow from "../../components/BackArrow";
import { useMessageBoard } from "../../components/assets/MessageBoard";
import { useSelector } from "react-redux";
import { selectCaseTypes, selectCategories, selectCourts, selectDamageAmounts, selectFilerTypes, selectFilingCodes, selectPartyTypes, selectPaymentAccounts, selectProcedureRemedies } from "../../components/assets/CaseWizard";
import EditIcon from '@mui/icons-material/Edit';
import { useFormik } from "formik";
import * as yup from 'yup';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ReplayIcon from '@mui/icons-material/Replay';
import { useAuth } from "../../hooks/auth";
import useAttorneys from "../../hooks/useAttorneys";
import useServiceContacts from "../../hooks/useServiceContacts";
import FormSelect from "../../components/forms/FormSelect";
import DialogCloseable from "../../components/dialogs/DialogCloseable";
import ServiceContactForm from "../serviceContact/ServiceContactForm";
import useConfigs from "../../hooks/useConfigs";
import usePaymentAccounts from "../../hooks/usePaymentAccount";
import DialogMissingPaymentAccount from "../../components/dialogs/DialogMissingPaymentAccount";
import PaymentAccountForm from "../payment-accounts/PaymentAccountsForm";
import StripePaymentAccountsForm from "../payment-accounts/StripePaymentAccountsForm";

export const CaseDetails = ({ fcase, goToStep, onFCaseChange, fetchFcase, onCaseInformationEdit, onPartiesEdit, onFilingsEdit, onServiceContactsEdit, showFees = false, editable = true, type = 'new-case', ...props }: { fcase: Case, goToStep?: Function, onFCaseChange?: Function, fetchFcase?: Function, onCaseInformationEdit?: Function, onPartiesEdit?: Function, onFilingsEdit?: Function, onServiceContactsEdit?: Function|boolean, showFees?: boolean, editable?: boolean, type?: 'new-case'|'existing-case'|'sent-e-service'}): ReactElement => {

  const {axios} = useAuth({middleware: 'auth'});
  
  const {isVisible, isRequired, configsReady} = useConfigs();

  const courts = useSelector(selectCourts)
  const categories = useSelector(selectCategories)
  const caseTypes = useSelector(selectCaseTypes)
  const paymentAccounts = useSelector(selectPaymentAccounts)
  const damageAmounts = useSelector(selectDamageAmounts)
  const procedureRemedies = useSelector(selectProcedureRemedies)
  const partyTypes = useSelector(selectPartyTypes)
  const filingCodes = useSelector(selectFilingCodes)
  const filerTypes = useSelector(selectFilerTypes)
  const {attorneys} = useAttorneys()

  const [showAddNewServiceContact, setShowAddNewServiceContact] = useState(false)
  const [fees, setFees] = useState([]);
  const [feesTotal, setFeesTotal] = useState(0);

  const { addMessageError, addMessageSuccess } = useMessageBoard();
  const { saveFilingCase, updateFilingCaseAttorney, updateFilingCasePaymentAccount, updateFilingCaseFilerCase, updateFilingCaseMFRPaymentAccount} = useFilingCases();
  const { removeServiceContactFromCase } = useServiceContacts();


  useEffect(() => {
    if(!fcase) {
      return;
    }
    formik.setValues(fcase);
    if(fcase.attorney_id && fcase.payment_account_id) {
      fetchFees();
    }
  }, [fcase, showFees]);

  const fetchFees = async () => {
    if(!showFees || !fcase?.id || fcase.filing_files.length === 0 || !fcase.parties.find((party: Party) => party.filing_party)) {
      setFees([]);
      setFeesTotal(0);
      return;
    }
    try {
      const urls: any = {
        'new-case': 'fees',
        'existing-case': 'fees-existing',
        'sent-e-service': 'fees-e-service',
      }
      const {data} = await axios.get(`filing-cases/${fcase.id}/${urls[type]}`);
      setFees(data.fees)
      setFeesTotal(data.total)
    } catch(error: any) {
      if (error?.response?.data?.error?.message) {
        addMessageError(error?.response?.data?.error?.message);
      }
    }
    // setFeesTotal(data.reduce((total: number, fee:any) => total + fee.amount, 0));
  }
  // --------------------------------------------
  //#TODO: todo esto ver
  const getCourt = () => {
    if (fcase?.location) {
      return fcase?.location.name;
    }
    if (fcase?.location_id) {
      return (courts?.find((court: any) => court.id == fcase.location_id))?.name;
    }
    return '';
  }

  const getCategory = () => {
    if(fcase?.case_type?.case_category) {
      return fcase.case_type.case_category.name
    }
    if(fcase?.case_category_id) {
      return (categories?.find((category: any) => category.id == fcase.case_category_id))?.name;
    }
    return '';
  }

  const getCaseType = () => {
    if (fcase?.case_type) {
      return fcase.case_type.name
    }
    if (fcase?.case_category_id) {
      return (caseTypes?.find((caseType: any) => caseType.id == fcase.case_type_id))?.name;
    }
    return '';
  }

  const getAttorney = () => {
    if (fcase?.attorney?.user) {
      return makeName(fcase.attorney.user)
    }
    if (fcase?.attorney_id) {
      const attorney = attorneys?.find((attorney: any) => attorney.id == fcase.attorney_id)
      return attorney ? makeName(attorney) : ''
    }
    return '';
  }

  const getFilerType = () => {
    if (fcase?.filer_type) {
      return fcase.filer_type.name
    }
    if (fcase?.filer_type_id) {
      const filerType = filerTypes?.find((filer_type: any) => filer_type.id == fcase.filer_type_id)
      return filerType ? filerType.name : ''
    }
    return '';
  }

  const getPaymentAccount = () => {
    if (fcase?.payment_account) {
      return fcase.payment_account.name
    }
    if (fcase?.payment_account_id) {
      return (paymentAccounts.find((paymentAccount: any) => paymentAccount.id == fcase.payment_account_id))?.name
    }
    return '';
  }

  const getProcedureRemedies = () => {
    if (!fcase || fcase.filing_case_procedure_remedies?.length === 0) {
      return '';
    }
    return (procedureRemedies
      .filter((procedureRemedy: any) => fcase.filing_case_procedure_remedies.includes(procedureRemedy.id))
      .map((procedureRemedy: any) => procedureRemedy.name)).join(', ');
  }

  const getDamageSought = () => {
    if (!fcase || !fcase.damage_amount_id) {
      return '';
    }
    return (damageAmounts.find((damageAmount: any) => damageAmount.id == fcase.damage_amount_id))?.name;
  }
  
  // --------------------------------------------

  const updateFilingParty = async (party: Party, value: boolean) => {
    await axios.put(`/filing-cases/${party.filing_case_id}/parties/${party.id}`, {...party, filing_party: value});
    onFCaseChange && onFCaseChange();
  }

  const getPartyType = (party: Party) => {
    if(party?.party_type) {
      return party.party_type.name;
    }
    return (partyTypes.find((partyType: any) => partyType.id == party.party_type_id))?.name;
  }

  const getFilingCode = (filing: any) => {
    if(filing?.filing_code) {
      return filing.filing_code.name;
    }
    return (filingCodes.find((filingCode: any) => filingCode.id == filing.filing_code_id))?.name;
  }

  const onSubmitServiceContacts = () => {
    setShowAddNewServiceContact(false);
    onFCaseChange && onFCaseChange();
  }

  // --------------------------------------------

  const makeServiceContactName = (service_contact: any) => {
    return service_contact.user ? makeName(service_contact.user) : makeName(service_contact);
  }

  const makeFirmName = (service_contact: any) => {
    return service_contact.user?.firm?.name ? service_contact.user?.firm?.name : service_contact.firm_name;
    // return service_contact.firm ? service_contact.firm.name : service_contact.firm_name;
  }

  const makeEmail = (service_contact: any) => {
    return service_contact.user ? service_contact.user.email : service_contact.email;
  }

  // --------------------------------------------

  const onAttroneyChange = async (attorney: any) => {
    if(!attorney) {
      return;
    }
    await updateFilingCaseAttorney(fcase, attorney.id);
    fetchFcase && fetchFcase();
  }
  
  const onPaymentAccountChange = async (payment_account: any) => {
    if(!payment_account) {
      return;
    }
    await updateFilingCasePaymentAccount(fcase, payment_account.id);
    fetchFcase && fetchFcase();
  }
  
  const onMFRPaymentAccountChange = async (mfr_payment_account: any) => {
    if(!mfr_payment_account) {
      return;
    }
    await updateFilingCaseMFRPaymentAccount(fcase, mfr_payment_account.id);
    fetchFcase && fetchFcase();
  }
  
  const onFilerTypeChange = async (filer_type: any) => {
    if(!filer_type) {
      return;
    }
    await updateFilingCaseFilerCase(fcase, filer_type.id);
    fetchFcase && fetchFcase();
  }

  const formik = useFormik({
    initialValues: fcase,
    validationSchema: () => {
      const validations: any = {
        attorney_id: yup.string().required("Please select an attorney"),
        payment_account_id: yup.string().required("Please select a payment account")
      };
      return yup.object().shape(validations);
    },
    onSubmit: async (values, { setSubmitting }) => {
      // return await saveFcase(values)
    }
  });

  const removeServiceContact = async (serviceContact: any) => {
    await removeServiceContactFromCase(fcase, serviceContact)
    onFCaseChange && onFCaseChange()
  }

  const onServiceContactsEditClick = () => {
    if(typeof onServiceContactsEdit === 'function') {
      onServiceContactsEdit();
    } else {
      setShowAddNewServiceContact(true);
    }
  }

  // Payments Account
  const [showAddNewPaymentAccount, setShowAddNewPaymentAccount]: [boolean, Function] = useState(false)
  const [showAddNewMFRPaymentAccount, setShowAddNewMFRPaymentAccount]: [boolean, Function] = useState(false)
  const {fetchPaymentAccounts2} = usePaymentAccounts();
  const [MFRAccountsOptions, setMFRAccountsOptions] = useState([]);
  const onSubmitPaymentAccounts = () => {
    // fetchPaymentAccounts()
    setShowAddNewPaymentAccount(false);
  }
  const handleCreatedMFRPaymentAccount = async () => {
    const results = await fetchPaymentAccounts2(undefined,undefined, undefined,'stripe');
    setMFRAccountsOptions(results);
  };
  useEffect(() => {
    const fetchMFRAccounts = async () => {
      const results = await fetchPaymentAccounts2(undefined,undefined, undefined,'stripe');
      setMFRAccountsOptions(results);
    };

    fetchMFRAccounts();
  }, []);

  if (!fcase || !configsReady) {
    return <></>
  }

  return <>
    <div className="flex gap-4 pt-4">
      <div className="flex-1 flex flex-col gap-4">
        <table className="summary-table">
          <thead>
            <tr>
              <th>Case Information</th>
              <th>
                {
                  onCaseInformationEdit && <EditIcon
                    onClick={() => onCaseInformationEdit()}
                    className="cursor-pointer ml-1 hover:opacity-80"
                  />
                }
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Location</td>
              <td>{getCourt()}</td>
            </tr>
            <tr>
              <td>Case Category</td>
              <td>{getCategory()}</td>
            </tr>
            <tr>
              <td>Case Type</td>
              <td>{getCaseType()}</td>
            </tr>
            {
              isVisible('FilingFilerType') && <tr>
                <td>Filer Type</td>
                <td>{getFilerType()}</td>
              </tr>
            }
            <tr>
              <td>Filing Attorney</td>
              <td>{getAttorney()}</td>
            </tr>
            <tr>
              <td>Payment Account</td>
              <td>{getPaymentAccount()}</td>
            </tr>
            {
              fcase.filing_case_procedure_remedies?.length > 0 && <tr>
                <td>Procedure Remedies</td>
                <td>{getProcedureRemedies()}</td>
              </tr>
            }
            {
              fcase.damage_amount_id && <tr>
                <td>Damage Sought</td>
                <td>{getDamageSought()}</td>
              </tr>
            }
          </tbody>
        </table>

        <table className="summary-table">
          <thead>
            <tr>
              <th colSpan={2}>Parties</th>
              <th>
                {
                  onPartiesEdit && <EditIcon
                    onClick={() => onPartiesEdit()}
                    className="cursor-pointer ml-1 hover:opacity-80"
                  />
                }
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="sub-title">
              <td>Filing Party</td>
              <td>Party Type</td>
              <td>Name</td>
            </tr>
            {
              fcase.parties.map((party: Party, index) => {
                return <tr key={`party-${index}`}>
                  <td>
                    <input type="checkbox"
                      onChange={(e) => updateFilingParty(party, e.target.checked)}
                      disabled={!editable}
                      {...{checked: party.filing_party}}/>
                  </td>
                  <td>{getPartyType(party)}</td>
                  <td>{makeName(party)}</td>
                </tr>
              })
            }
          </tbody>
        </table>

        <table className="summary-table">
          <thead>
            <tr>
              <th colSpan={2}>Filins</th>
              <th>
                {
                  onFilingsEdit && <EditIcon
                    onClick={() => onFilingsEdit()}
                    className="cursor-pointer ml-1 hover:opacity-80"
                  />
                }
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="sub-title">
              <td>Request</td>
              <td>Description</td>
              <td>Reference Number</td>
            </tr>
            {
              fcase.filing_files && fcase.filing_files.map((filing: Filing, index) => {
                return <tr key={`filing-${index}`}>
                  <td>{getFilingCode(filing)}</td>
                  <td>{filing.description}</td>
                  <td>{filing.client_number}</td>
                </tr>
              })
            }
          </tbody>
        </table>

        <table className="summary-table">
          <thead>
            <tr>
              <th colSpan={2}>Service Contacts</th>
              <th colSpan={2}>
                {
                  onServiceContactsEdit && <EditIcon
                    onClick={() => onServiceContactsEditClick()}
                    className="cursor-pointer ml-1 hover:opacity-80"
                  />
                }
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="sub-title">
              <td>Name</td>
              <td>Firm/Organization</td>
              <td className="text-right">Email</td>
              {
                editable && onServiceContactsEdit && <td style={{width: '1%'}}></td>
              }
            </tr>
            {
              fcase.service_contacts && fcase.service_contacts.map((service_contact: any, index) => {
                return <tr key={`service-contact-${index}`}>
                  <td>{makeServiceContactName(service_contact)}</td>
                  <td>{makeFirmName(service_contact)}</td>
                  <td className="text-right">{makeEmail(service_contact)}</td>
                  {
                    editable && onServiceContactsEdit && <td className="text-right">
                      <RemoveCircleIcon
                        onClick={() => removeServiceContact(service_contact)}
                        className="cursor-pointer hover:opacity-75"
                      />
                    </td>
                  }
                </tr>
              })
            }
          </tbody>
        </table>
      </div>

      <div className="flex-1 flex flex-col gap-4">
        {
          showFees && <table className="summary-table">
            <thead>
              <tr>
                <th>Fees</th>
                <th>
                  <ReplayIcon
                    onClick={fetchFees}
                    className="cursor-pointer ml-1 hover:opacity-80"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {
                fees && fees.map((fee: any, index: number) => <tr key={`fee-${index}`}>
                  <td>{fee.name}</td>
                  <td>{currency(fee.amount)}</td>
                </tr>)
              }
            </tbody>
            <tfoot>
              <tr className="font-bold border-t-2 border-gray-300">
                <td>Envelope Total</td>
                <td>{currency(feesTotal)}</td>
              </tr>
            </tfoot>
          </table>
        }
        {
          isVisible('FilingFilerType') && <table className="summary-table">
            <thead>
              <tr>
                <th>Filer Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                editable && <tr>
                  <td colSpan={2}>
                    <FormLabel mandatory={isRequired('FilingFilerType')}>Filer Type</FormLabel>
                    <FormSelect
                      name="filer_type_id"
                      formik={formik}
                      options={filerTypes}
                      optionValue="id"
                      optionLabel="name"
                      empty="Select Filer Type"
                      onChange={onFilerTypeChange}
                      clearable={false}
                    />
                  </td>
                </tr>
              }
              {
                !editable && <tr>
                  <td>Filer Type</td>
                  <td>{makeName(fcase.filer_type)}</td>
                </tr>
              }
            </tbody>
          </table>
        }
        <table className="summary-table">
          <thead>
            <tr>
              <th>Payment</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              editable && <tr>
                <td colSpan={2}>
                  <FormLabel mandatory>Court Fees Payment Account</FormLabel>
                  <FormSelect
                    name={'payment_account_id'}
                    formik={formik}
                    options={paymentAccounts}
                    optionValue="id"
                    optionLabel="name"
                    empty={false}
                    onChange={onPaymentAccountChange}
                    clearable={false}
                  />
                </td>
              </tr>
            }
            {
              !editable && <tr>
                <td>Court Fees Payment Account</td>
                <td>{fcase.payment_account?.name}</td>
              </tr>
            }
            {
              editable && <tr>
                <td colSpan={2}>
                  <FormLabel mandatory>MFR Fees Payment Account</FormLabel>
                  <FormSelect
                    name={'mfr_payment_account_id'}
                    formik={formik}
                    options={MFRAccountsOptions}
                    optionValue="id"
                    optionLabel="name"
                    empty={false}
                    onChange={onMFRPaymentAccountChange}
                    clearable={false}
                  />
                </td>
              </tr>
            }
            {
              !editable && <tr>
                <td>MFR Fees Payment Account</td>
                <td>{fcase.payment_account?.name}</td>
              </tr>
            }
          </tbody>
        </table>

        <table className="summary-table">
          <thead>
            <tr>
              <th>Filing Attorney</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              editable && <tr>
                <td colSpan={2}>
                  <FormLabel mandatory>Filing Attorney</FormLabel>
                  <FormSelect
                    name="attorney_id"
                    formik={formik}
                    options={attorneys}
                    optionValue="id"
                    optionLabel={makeName}
                    empty={false}
                    onChange={onAttroneyChange}
                    clearable={false}
                  />
                </td>
              </tr>
            }
            {
              !editable && <tr>
                <td>Filing Attorney</td>
                <td>{makeName(fcase.attorney?.user)}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
    <DialogCloseable
      open={showAddNewServiceContact}
      onClose={() => setShowAddNewServiceContact(false)}
      title="Service Contacts"
      width="1000px"
      subtitle="Please fill in the following information."
    >
      <ServiceContactForm onSubmitSuccess={onSubmitServiceContacts} fcase={fcase}/>
    </DialogCloseable>

    <DialogCloseable
      open={showAddNewPaymentAccount}
      onClose={() => setShowAddNewPaymentAccount(false)}
      title="Add Payment Account"
    >
      <PaymentAccountForm onSubmitSuccess={() => onSubmitPaymentAccounts()} />
    </DialogCloseable>
    <DialogCloseable
      open={showAddNewMFRPaymentAccount}
      onClose={() => setShowAddNewMFRPaymentAccount(false)}
      title="Add Payment Account"
      width="700px"
    >
      <StripePaymentAccountsForm onSubmitSuccess={handleCreatedMFRPaymentAccount}/>
    </DialogCloseable>
    <DialogMissingPaymentAccount 
      setShowAddCourtPaymentAcc={setShowAddNewPaymentAccount}
      setShowAddMFRPaymentAcc={setShowAddNewMFRPaymentAccount}
    />
  </>
};

export const CaseDetailsScreen = ({ target, targetButtonTitle, type = 'new-case', ...props }: {target?: string, targetButtonTitle?: string, type?: 'new-case'|'existing-case'|'sent-e-service'}): ReactElement => {

  const navigate = useNavigate();
  const { id, location_id } = useParams();
  const [fcase, setFcase]: [Case | undefined, Function] = useState();
  const [isMfr, setIsMfr] = useState(true);
  const { fetchFilingCaseById, fetchExistingFilingCaseById } = useFilingCases();
  const { addMessageError } = useMessageBoard();

  useEffect(() => {
    if (!id) {
      return;
    }
    if (/[a-f0-9]+-[a-f0-9-]+/.test(id) && location_id) {
      setIsMfr(false)
      fetchExistingFcase(id, parseInt(location_id))
    } else if (/[0-9]+/.test(id)) {
      setIsMfr(true)
      fetchMfrFcase(parseInt(id))
    }
  }, [id]);

  const fetchMfrFcase = async (id: number) => {
    try {
      const fcase = await fetchFilingCaseById(id, true)
      setFcase(fcase);
    } catch (e: any) {
      addMessageError(e.message);
    }
  }

  const fetchExistingFcase = async (id: string, location_id: number) => {
    try {
      const fcase = await fetchExistingFilingCaseById(id, location_id);
      setFcase(fcase);
    } catch (e: any) {
      addMessageError(e.message);
    }
  }

  const fileThisCase = async () => {
    if(type == 'new-case') {
      return;
    }
    try {
      const fcase = await fetchExistingFilingCaseById(`${id}`, parseInt(`${location_id}`), type, true);
      navigate(`${target}/${fcase?.id}`);
    } catch (e: any) {
      addMessageError(e.message);
    }
  }

  return <PrivateLayout>
    <BackArrow />
    {
      fcase && <RoundedBox className="bg-white mt-4">
        <div className="flex border-b-gray-300 border-b-1 pb-2">
          <div className="flex-1 flex flex-col justify-center">
            <FormLabel>
              Filing Summary
            </FormLabel>
          </div>
          <div className="flex-initial">
            {
              target && !isMfr && <FormButton className="px-20" onClick={fileThisCase}>
                {targetButtonTitle ? targetButtonTitle : target}
              </FormButton>
            }
          </div>
        </div>
        <CaseDetails
          fcase={fcase}
          showFees={false}
          editable={false}
          type={type}
        />
      </RoundedBox>
    }
  </PrivateLayout>
}

export const FileIntoExistingCaseDetailsScreen = (): ReactElement => <CaseDetailsScreen target="/file-into-existing-case" targetButtonTitle="File Into This Case" type="existing-case"/>

export const EServiceCaseDetailsScreen = (): ReactElement => <CaseDetailsScreen target="/e-service" targetButtonTitle="File To Attorney" type="e-service"/>