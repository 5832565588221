import React from "react";
import { Typography, Tabs } from "@mui/material";
import PrivateLayout from "../../components/layout/PrivateLayout";
import RoundedBox from "../../components/layout/RoundedBox";
import { StyledTab } from "../my-filings/MyFilings";
import CourtPaymentAccount from "./payment-accounts/CourtPaymentAccount";
import MFRPaymentAccounts from "./payment-accounts/MFRPaymentAccounts";
import { useSearchParams } from "react-router-dom";

const PaymentAccountsPage = (): React.ReactElement => {

  // Tabs
  const [searchParams, setSearchParams] = useSearchParams();
  type TabValue = 'court-accounts' | 'mfr-accounts';
  const currentTab = (searchParams.get("tab") as TabValue) || 'court-accounts';
  const tabValues: TabValue[] = ['court-accounts', 'mfr-accounts'];
  const getTabIndex = (value: TabValue): number => {
    return tabValues.indexOf(value);
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSearchParams({ tab: tabValues[newValue] });
  };

  // Function to render the content for the selected tab
  const renderTabContent = () => {
    switch (currentTab) {
      case 'court-accounts':
      default:
        return <CourtPaymentAccount />;
      case 'mfr-accounts':
        return <MFRPaymentAccounts />;
    }
  };
  return (
    <PrivateLayout>
      <RoundedBox color="normal">
        <div className="bg-white p-6 rounded-2xl">
          <RoundedBox className="p-4 border-2 border-base-blue rounded-xl mb-4 text-start">
            {/* <Typography variant="h6" className="text-black" sx={{ fontSize: 14 }}>
              Create or view the payment accounts associated with your firm.
            </Typography> */}
            <Typography variant="h6" className="text-black" sx={{ fontSize: 14 }}>
              Create, edit, view, add the payment accounts associated with your firm.
            </Typography>
            <Typography variant="h6" className="text-red-500" sx={{ fontSize: 14 }}>
              Note:
              <ul>
                <li>CMS stands form <b>Court Managment System</b> and is associated with eFileCA court fees.</li>
                <li>EFSP stands for <b>E-Filing Service Provider</b> and is associated with the use of MFR as a Filing Service Provider.</li>
              </ul>
            </Typography>
          </RoundedBox>
          
          <Tabs
            value={getTabIndex(currentTab)}
            onChange={handleTabChange}
            centered
            variant="fullWidth"
            className="bg-[#F0F2F5] p-2 rounded-2xl mb-10"
            TabIndicatorProps={{
              style: {
                display: "none", // Hide the bottom indicator
              },
            }}
          >
            <StyledTab label="Court Fees payment accounts" />
            <StyledTab label="MFR Fees payment accounts" />
          </Tabs>
          <div className="tab-content h-[50vh]">{renderTabContent()}</div>
        </div>
      </RoundedBox>
    </PrivateLayout>
  );
};

export default PaymentAccountsPage;
