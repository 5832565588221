import { ReactElement, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as yup from 'yup';
import FormInput from "../../components/forms/FormInput";
import FormLabel from "../../components/forms/FormLabel";
import { Button, FormButton } from "../../components/forms/FormButton";
import { selectAuth, useAuth } from "../../hooks/auth";
import { useMessageBoard } from "../../components/assets/MessageBoard";
import FormTextarea from "../../components/forms/FormTextarea";
import FormSelect from "../../components/forms/FormSelect";
import DialogCloseable from "../../components/dialogs/DialogCloseable";
import FormError from "../../components/forms/FormError";
import usePaymentAccounts, { PaymentAccount } from "../../hooks/usePaymentAccount";
import { getCurrentApiUrl } from "../../utils/utils";
import AddCardDetailForm from "./components/AddCardDetailForm";
import useStripe from "../../hooks/useStripe";
import { useSelector } from "react-redux";
import { Profile } from "../../repositories/Models";
import AddStripeCardDetailForm from "./components/AddStripeCardDetailForm";
import useAccount from "../../hooks/useAccount";

const StripePaymentAccountForm = ({ paymentAccountId = null, onSubmitSuccess = null }: { paymentAccountId?: null | number, onSubmitSuccess?: null | Function }): ReactElement => {
  const { axios } = useAuth({ middleware: 'auth' });
  const { addMessageSuccess } = useMessageBoard();
  const [showAddDetails, setShowAddDetails] = useState(false);
  const [requestXML, setRequestXML] = useState('');
  const {createPaymentAccount} = usePaymentAccounts()
  const hiddenForm = useRef(null);
  const {profile, fetchProfile} = useAccount();

  const { createCustomer, attachPaymentMethod, error: stripeError } = useStripe();
  useEffect(() => {
    // this event is triggered from mfr api endpoint where the iframe is redirected after success /payment-accounts/toga-token
    const togaTokenCreated = async (event: any) => {
      if(event.data._event_type && event.data._event_type == 'toga-token') {
        setShowAddDetails(false);

        const field_names = ['account_token', 'card_company', 'card_holder_name', 'card_holder_address', 'card_last4', 'card_year', 'card_month'];
        for(let field_name of field_names) {
          await formik.setFieldValue(field_name, event.data[field_name]);
          await formik.setFieldTouched(field_name, true);
        }
        setTimeout(async () => {
          await formik.submitForm();
        }, 10)
      }
    };
    window.addEventListener('message', togaTokenCreated);

    return () => {
      window.removeEventListener('message', togaTokenCreated);
    };
  }, []);

  useEffect(() => {
    if (!paymentAccountId) {
      return
    }
    //#TODO: fetch from WS
  }, [paymentAccountId]);

  const types = [
    {
      code: 'CC',
      name: 'Credit Card'
    }, {
      code: 'BankAccount',
      name: 'Bank Debit'
    }
  ];

  const initialValues = {
    name: '',
    type: 'CC',
    note: '',
    is_global: false,
    account_token: 'stripe'
  };

  const [formikData, setFormikData] = useState(initialValues);
  const formik = useFormik({
    initialValues,
    validationSchema: (a: any) => {
      const rules: any = {
        name: yup.string().required("Please enter the account name"),
      };
      return yup.object().shape(rules);
    },
    onSubmit: async (values, { setSubmitting }) => {
      const data = { ...values, is_global: false };
      //#TODO: ver esto is global con permisos o algo!!
      try {
        // await createPaymentAccount(data)
        // addMessageSuccess('The payment account was created')
        // onSubmitSuccess && onSubmitSuccess()

        // const errors = await formik.validateForm();
        // if (!!errors.name) {
        //   formik.setTouched({name: true});
        //   return false;
        // }
        // console.log('init values', formik.values);
        setFormikData(data);
        setShowAddDetails(true);
      } catch (e) {

      }
    }
  });

  const addDetails = async () => {
    const errors = await formik.validateForm();
    if (!!errors.name) {
      formik.setTouched({name: true});
      return false;
    }
      
    setShowAddDetails(true)
  }

  const onCancel = () => {
    setShowAddDetails(false);
  }

  const onCreate = async (data: any) => {
    try {
      let stripeCustomerId;
      // Check if firm has Stripe Customer ID
      await fetchProfile();
      if (!profile?.firm?.stripe_customer_id) {
        stripeCustomerId = await createCustomer({});
      } else {
        stripeCustomerId = profile.firm?.stripe_customer_id;
      }
      // Attach Payment Method to Customer
      await attachPaymentMethod(stripeCustomerId, data.stripe_id)
      if (stripeError) {
        throw stripeError;
      }
      addMessageSuccess('The Payment Method successfully attached');
      
      // Finally, create the PaymentAccount
      const newData = {...formikData, ...data, account_token: 'stripe', mfr_type: 'stripe'};
      await createPaymentAccount(newData);
      addMessageSuccess('The payment account was created');
      onSubmitSuccess && onSubmitSuccess();
    } catch (e) {
      console.log('error', e);
    }
  };
  
  return <>
  {showAddDetails && (<AddStripeCardDetailForm onCancel={onCancel} onCreate={onCreate}/>)}
  {!showAddDetails && ( 
    <form onSubmit={formik.handleSubmit} className="text-sm">
      <p className="block font-normal text-base-gray mb-6">
        Enter the information for the new payment account. 
        The fields marked with a red asterisk (<span className="text-red-700 font-bold relative">*</span>) are required fields.
      </p>
      <span className="text-base-blue text-base font-bold mb-4">Payment Account Information</span>
      <div className="mb-4">
        <FormLabel mandatory>
          Payment Account Type
        </FormLabel>
        <FormSelect
          required
          options={types}
          name="type"
          formik={formik}
          optionValue='code'
          optionLabel='name'
        />
      </div>

      <div className="mb-4">
        <FormLabel mandatory>
          Payment Account Name
        </FormLabel>
        <FormInput
          formik={formik}
          name="name"
        />
      </div>
      <div className="mb-4">
        <FormLabel>
          Note
        </FormLabel>
        <FormTextarea
          formik={formik}
          name="note"
        />
      </div>
      <div className="text-center">
        {
          formik.values?.type != 'WV' && 
          <FormButton
            className="m-auto w-full inline-block" 
            // onClick={addDetails}
          >
            { formik.values?.type === types[0].code ? 'Add Card Details' : '' }
            { formik.values?.type === types[1].code ? 'Add Account Details' : '' }
          </FormButton>
        }
      </div>

    </form>
  )}
  </>;
};

export default StripePaymentAccountForm;

