import { ReactElement, useEffect, useState } from "react";
import PrivateLayout from "../components/layout/PrivateLayout";
import RoundedBox from "../components/layout/RoundedBox";
import { selectAuth, useAuth } from "../hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import { DateTime } from "luxon";
import { User } from "../repositories/Models";
import { useSelector } from "react-redux";
import Notifications from "../components/Notifications";
import {SearchCaseDialogEService} from "./cases/SearchCaseDialog";
import DialogCloseable from "../components/dialogs/DialogCloseable";
 
import { FormButton } from "../components/forms/FormButton";
import usePaymentAccounts from "../hooks/usePaymentAccount";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


const firmAdmins = [{
  firstName: 'Jhon',
  lastName: 'Doe',
  phone: '(234) 111-5555',
  email: 'jhondoe@gmail.com'
},
{
  firstName: 'Jane',
  lastName: 'Doe',
  phone: '(234) 555-2312',
  email: 'janedoe@tyler.com'
}];
const HomeScreen = (): ReactElement => {
  const { getUserData, hasRole} = useAuth({ middleware: "auth" });
  const {
    loading,
    getCountPaymentAccounts,
} = usePaymentAccounts();
  // const [user, setUser]: [User | undefined, Function] = useState();
  const user: User = useSelector(selectAuth);
  // const user:User|undefined = getUserData();
  const [fillingAccepted, setFillingAccepted]: [number | undefined, Function] =
    useState();
  const [fillingSubmitted, setFillingSubmitted]: [
    number | undefined,
    Function,
  ] = useState();
  const [fillingRejected, setFillingRejected]: [number | undefined, Function] = useState();
  const [fillingFrom, setFillingFrom]: [string | undefined, Function] = useState();

  const [showSearchCaseDialog, setShowSearchCaseDialog]: [any, Function] = useState(false);

  const onFileIntoAnExistingCaseClick = () => {
    setShowSearchCaseDialog(true)
  }

  useEffect(() => {
    getUserData();
    //#TODO: get real data
    setFillingAccepted("08");
    setFillingSubmitted("05");
    setFillingRejected("03");
    setFillingFrom(
      DateTime.now().minus({ days: 15 }).toFormat("cccc, d'th at' hh:mm'hs'"),
    );
  }, []);

  const [countTogaAccounts, setCountTogaAccounts] = useState(0);
  const [countStripeAccounts, setCountStripeAccounts] = useState(0);
  useEffect(() => {
    const countPayments = async () => {
      try {
        const results = await getCountPaymentAccounts();
        setCountStripeAccounts(results.stripe);
        setCountTogaAccounts(results.toga);
        if(!hasRole(['admin', 'firm_admin'])) {
          // fetch admins, consultar
        };
  
        if(results?.stripe <= 0 || results?.toga <= 0)
          setMissingPaymentMethod(true);
      } catch(e: any) { }
    };
    countPayments();
  }, [user]);

  // check payment
  const [missingPaymentMethods, setMissingPaymentMethod] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      {user && (
        <PrivateLayout>
          <RoundedBox
            className="bg-background-overlay-blue bg-home-title-man bg-no-repeat bg-contain mb-4 relative"
            style={{ backgroundPosition: "top 0 right 2.5em" }}
          >
            <div className="p-6">
              <h2 className="text-base-blue text-2xl mb-2">
                Welcome back {user.first_name},
              </h2>
              <p>Have a nice day at work!</p>
            </div>
            <Notifications />
          </RoundedBox>
          <div className="flex gap-4 mb-4">
            <RoundedBox className="bg-white w-3/5 text-center">
              <h3 className="text-2xl mb-6">FILE TO COURT</h3>
              <p className="mb-6">
                File documents to court. You may also simultaneously
                <br />
                send documents to opposing counsel.
              </p>
              <p className="text-base-blue font-bold mb-6">
                No time to File? We can do it for you.
              </p>
              <Link
                to="/file-to-court"
                className="px-16 py-3 text-white bg-base-blue rounded hover:bg-hovered-light-blue active:bg-active-light-blue inline-block"
              >
                <FileUploadIcon className="mr-2" />
                File Documents
              </Link>
            </RoundedBox>
            <RoundedBox className="bg-white w-2/5 text-center">
              <h3 className="text-2xl mb-6">SERVE TO ATTORNEY</h3>
              <p className="mb-16">
                Send docs to opposing counsel only.
                <br />
                Not filed with court.
              </p>
              <a
                className="px-16 py-3 text-base-blue bg-background-overlay-blue rounded hover:bg-hovered-light-blue hover:text-white active:bg-active-light-blue inline-block cursor-pointer"
                onClick={onFileIntoAnExistingCaseClick}
              >
                <FileUploadIcon className="mr-2" />
                Send Documents
              </a>
              <SearchCaseDialogEService
                open={showSearchCaseDialog}
                onClose={() => setShowSearchCaseDialog(false)}
              />
            </RoundedBox>
          </div>
          <RoundedBox className="bg-background-overlay-blue flex gap-4">
            <div className="w-1/4 flex-initial pl-6 py-10 flex">
              <div className="flex-1 flex items-center">
                <div>
                  <p className="text-xl mb-6 font-semibold">
                    Updates on
                    <br />
                    your Filings!
                  </p>
                  <p>
                    Your Filings statistics since
                    <br />
                    {fillingFrom}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-3/4 flex-grow flex gap-4">
              <RoundedBox className="bg-background-blue flex-1 text-center">
                <p className="font-bold">New Filings Accepted</p>
                <div className="text-white rounded-full text-4xl font-bold size-16 flex items-center text-center my-8 bg-green-600 opacity-50 m-auto">
                  <div className="flex-1 text-center">{fillingAccepted}</div>
                </div>
                <Link
                  to={"#"}
                  className="text-center py-2 max-w-80 block rounded-full text-base-blue bg-background-overlay-blue hover:bg-hovered-light-blue hover:text-white m-auto"
                >
                  View All
                </Link>
              </RoundedBox>
              <RoundedBox className="bg-background-blue flex-1 text-center">
                <p className="font-bold">New Filings Submitted</p>
                <div className="text-white rounded-full text-4xl font-bold size-16 flex items-center text-center my-8 bg-yellow-400 opacity-50 m-auto">
                  <div className="flex-1 text-center">{fillingSubmitted}</div>
                </div>
                <Link
                  to={"#"}
                  className="text-center py-2 max-w-80 block rounded-full text-base-blue bg-background-overlay-blue hover:bg-hovered-light-blue hover:text-white m-auto"
                >
                  View All
                </Link>
              </RoundedBox>
              <RoundedBox className="bg-background-blue flex-1 text-center">
                <p className="font-bold">New Filings Rejected</p>
                <div className="text-white rounded-full text-4xl font-bold size-16 flex items-center text-center my-8 bg-red-600 opacity-50 m-auto">
                  <div className="flex-1 text-center">{fillingRejected}</div>
                </div>
                <Link
                  to={"#"}
                  className="text-center py-2 max-w-80 block rounded-full text-base-blue bg-background-overlay-blue hover:bg-hovered-light-blue hover:text-white m-auto"
                >
                  View All
                </Link>
              </RoundedBox>
            </div>
          </RoundedBox>
          <p className="my-4 text-base">Searching For a Report?</p>
          <RoundedBox className="bg-white flex rounded-full mb-4">
            <div className="flex-1 border-r border-background-overlay-blue px-4">
              <label className="text-black mb-1 block">Document Type</label>
              <input placeholder="My Filing or Firms Filing" />
            </div>
            <div className="flex-1 border-r border-background-overlay-blue px-4">
              <label className="text-black mb-1 block">
                Jurisdiction or Location *
              </label>
              <input placeholder="Select Location" />
            </div>
            <div className="flex-1 border-r border-background-overlay-blue px-4">
              <label className="text-black mb-1 block">Envelope Number</label>
              <input placeholder="Insert Envelope Number" />
            </div>
            <div className="flex-1 pl-4 flex">
              <div className="flex-1">
                <label className="text-black mb-1 block">Date</label>
                <input placeholder="Select Date Range" />
              </div>
              <button className="flex-initial rounded-full bg-base-blue text-white p-2 hover:bg-hovered-light-blue">
                <SearchIcon fontSize="large" />
              </button>
            </div>
          </RoundedBox>
          <p className="block text-right text-sm">
            <a
              href="#"
              className="hover:text-base-blue font-bold text-base-blue"
            >
              More filters&nbsp;
              <TuneIcon />
            </a>
          </p>
        </PrivateLayout>
      )}
        <DialogCloseable 
          width='600px'
          title="Hey there," 
          open={missingPaymentMethods}
          onClose={() => setMissingPaymentMethod(false)}
        >
          {hasRole(['admin', 'firm_admin']) ? (
            <>
              <p className="text-sm font-medium">There is no valid payment account associated with this firm, for the following</p>
              <ul className="list-disc pl-4 mb-2 text-sm font-medium">
                {countTogaAccounts <= 0 && <li>Court Fees <HelpOutlineIcon className="text-base-blue" sx={{ fontSize: 14 }} /></li>}
                {countStripeAccounts <= 0 &&<li>MFR Fees <HelpOutlineIcon className="text-base-blue" sx={{ fontSize: 14 }} /></li>}
              </ul>
              <p className="text-sm font-semibold">Please add a new payment account from the Admin Dashboard</p>
              <FormButton onClick={() => navigate('admin-dashboard/payment-accounts')} className="w-full text-center font-bold mt-6">Add new Payment Account</FormButton>
            </>
          ) : (
            <>
              <p className="text-sm font-medium">There is no valid payment account associated with this firm, for the following</p>
              <ul className="list-disc pl-4 mb-2 text-sm font-medium">
                {countTogaAccounts <= 0 && <li>Court Fees <HelpOutlineIcon className="text-base-blue" sx={{ fontSize: 14 }} /></li>}
                {countStripeAccounts <= 0 &&<li>MFR Fees <HelpOutlineIcon className="text-base-blue" sx={{ fontSize: 14 }} /></li>}
              </ul>
              <p className="text-sm font-semibold mb-2">Please contact your firm admin and ask them to add a new payment account from the Admin Dashboard</p>
              <span className="text-sm font-semibold">Firm Admins:</span>
              <ul className="mb-4">
              {firmAdmins && firmAdmins.map((admin, index) => (
                <li className="text-sm font-medium" key={index}>{`${admin.firstName} ${admin.lastName} - ${admin.phone} - ${admin.email}`}</li>
              ))}
              </ul>
              <p className="text-sm font-medium">All MFR features are accessible except for the fillings, until payment is updated</p>

            </>
          )}
          
        </DialogCloseable>
    </>
  );
};

export default HomeScreen;
