import { useAuth } from "./auth";
import { useCallback, useState } from "react";
import { HttpPaginationDataResponse, PaginationDataResponse, paginationEmpty, parseResponse } from "../components/table/AgTable";

export interface FirmsFilters extends PaginationDataResponse {
  search?: string
};

const useFirms = () => {
  const { axios } = useAuth({ middleware: 'auth' })
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [pagination, setPagination] = useState<FirmsFilters>({...paginationEmpty, perPage: 10});

  const fetchFirms = useCallback(async (options?: FirmsFilters) => {
    setLoading(true);
    setError(null);
    try {
      const params: any = {
        sort_by: "id_desc",
        items_per_page: options?.perPage || pagination.perPage,
        page: options?.currentPage || pagination.currentPage
      };
      delete params.currentPage

      if(options?.search) {
        params.search = options.search
      }

      const response: HttpPaginationDataResponse = (await axios.get('firms', {params})).data;

      setPagination({ ...pagination, ...options, ...parseResponse(response) });

      setLoading(false);
    } catch (err) {
      setError('Failed to fetch firms');
      setLoading(false);
    }
  }, []);

  return {
    fetchFirms,
    pagination
  };
};

export default useFirms;
