import { useAuth } from "./auth";
import { useCallback, useState } from "react";
import { HttpPaginationDataResponse, PaginationDataResponse, paginationEmpty, parseResponse } from "../components/table/AgTable";
import { User } from "./useUsers";

export interface AdminFilters extends PaginationDataResponse {
};

const useAdmins = () => {
  const { axios } = useAuth({ middleware: 'auth' })
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [pagination, setPagination] = useState<AdminFilters>(paginationEmpty);

  const fetchAdmins = useCallback(async (options?: AdminFilters) => {
    setLoading(true);
    setError(null);
    try {
      const params: any = {
        sort_by: "id_desc",
        items_per_page: options?.perPage || pagination.perPage,
        page: options?.currentPage || pagination.currentPage
      };
      delete params.currentPage

      const response: HttpPaginationDataResponse = (await axios.get('admin-users', {params})).data;

      setPagination({ ...pagination, ...options, ...parseResponse(response) });

      setLoading(false);
    } catch (err) {
      setError('Failed to fetch admins');
      setLoading(false);
    }
  }, []);

  const createAdmin = useCallback(async (email: string): Promise<void> => {
    await axios.post(`admin-users`, {email});
  }, []);

  const editAdmin = useCallback(async (admin: User): Promise<void> => {
    await axios.put(`admin-users/${admin.id}`, admin);
  }, []);

  const deleteAdmin = useCallback(async (id: number): Promise<void> => {
    await axios.delete(`admin-users/${id}`);
  }, []);

  return {
    fetchAdmins,
    createAdmin,
    editAdmin,
    deleteAdmin,
    pagination
  };
};

export default useAdmins;
