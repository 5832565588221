import { ReactElement } from "react";
import PrivateLayout from "../components/layout/PrivateLayout";
import RoundedBox from "../components/layout/RoundedBox";
import MyReports from "./my-reports/MyReports";

const MyReportsScreen = ():ReactElement => {
  return <PrivateLayout>
    <RoundedBox color="normal"><MyReports /></RoundedBox>
  </PrivateLayout>;
};

export default MyReportsScreen;
