import { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "./auth";
import { HttpPaginationDataResponse, PaginationData, PaginationDataRequest, PaginationDataResponse, paginationEmpty, parseResponse } from "../components/table/AgTable";

export interface Filing {
  id: number;
  envelope: string;
  caseNumber: string;
  caseTitle: string;
  clientMatterNumber: string;
  filingStatus: string;
  lastUpdate: string;
  description: string;
  filing_case: any;
}

export interface FilingFilters extends PaginationDataResponse {
  search?: string;
  
  statuses?: string[];
  envelope?: string;
  caseNumber?: string;
  clientMatterNumber?: string;
  attorneyId?: string;

  dateFrom?: Dayjs | null;
  dateTo?: Dayjs | null;
};

export const statuses = {
  submitting: "Submitting",
  failed: "Failed",
  submitted: "Submitted",
  accepted: "Accepted",
  rejected: "Rejected",
};

const useFilings = () => {
  const {axios} = useAuth({middleware: 'auth'})
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [pagination, setPagination] = useState<FilingFilters>(paginationEmpty);

  const fetchFilings = useCallback(async (url: string, filingTypes: string[], options?: FilingFilters) => {
    setLoading(true);
    setError(null);
    try {
      const params: any = {
        sort_by: "id_desc",
        items_per_page: options?.perPage || pagination.perPage,
        page: options?.currentPage || pagination.currentPage,
        filing_type: filingTypes
      };
      delete params.currentPage
      
      // Filter by statuses
      if (options?.statuses && options.statuses.length > 0) {
        params.statuses = options.statuses;
      }

      // Filter by search query
      if (options?.search) {
        params.search = options.search.toLowerCase();
      }

      // Filter by envelope
      if (options?.envelope) {
        params.envelope_number = options.envelope.toLowerCase();
      }

      // Filter by caseNumber
      if (options?.caseNumber) {
        params.case_number = options.caseNumber.toLowerCase();
      }

      // Filter by clientMatterNumber
      if (options?.clientMatterNumber) {
        params.client_matter_number = options.clientMatterNumber.toLowerCase();
      }
      
      // Filter by attorney
      if (options?.attorneyId) {
        params.attorney_id = `${parseInt(options.attorneyId)}`;
      }

      // **Filter by date range**
      if (options?.dateFrom || options?.dateTo) {
        if (options?.dateFrom) {
          // isAfterFrom = lastUpdateDate.isAfter(options.dateFrom, 'day');
          params.date_from = options.dateFrom.format('YYYY-MM-DD');
        }
        if (options?.dateTo) {
          params.date_to =  options.dateTo.format('YYYY-MM-DD');
          // isBeforeTo =  lastUpdateDate.isBefore(options.dateFrom, 'day');
        }
      };

      const response: HttpPaginationDataResponse = (await axios.get(url, {params})).data;

      setPagination({...pagination, ...options, ...parseResponse(response)});

      setLoading(false);
    } catch (err) {
      setError('Failed to fetch filings');
      setLoading(false);
    }
  }, []);

  const fetchCaseMyFilings = useCallback(async (options?: FilingFilters) => {
    return fetchFilings(`filing-cases/filing-files/my-filings`, ['new-case', 'existing-case'], options);
  }, []);

  const fetchCaseFirmFilings = useCallback(async (options?: FilingFilters) => {
    return fetchFilings(`filing-cases/filing-files/firm-filings`, ['new-case', 'existing-case'], options);
  }, []);

  const fetchCaseMyDraftFilings = useCallback(async (options?: FilingFilters) => {
    return fetchFilings(`filing-cases/filing-files/my-draft-filings`, ['new-case', 'existing-case'], options);
  }, []);

  const fetchEServiceMyFilings = useCallback(async (options?: FilingFilters) => {
    return fetchFilings(`filing-cases/filing-files/my-filings`, ['sent-e-service'], options);
  }, []);

  const fetchEServiceReceivedFilings = useCallback(async (options?: FilingFilters) => {
    return fetchFilings(`filing-cases/filing-files/my-filings`, ['received-e-service'], options);
  }, []);

  const fetchEServiceMyDraftFilings = useCallback(async (options?: FilingFilters) => {
    return fetchFilings(`filing-cases/filing-files/my-draft-filings`, ['sent-e-service'], options);
  }, []);

  const fetchFilingById = useCallback(async (filingFile: string | number) => {
    setLoading(true);
    setError(null);
    try {
      if (filingFile) {
        const results = (await axios.get(`filing-cases/filing-files/${filingFile}`, {})).data;
        return results;
      }
    } catch (err) {
      setError('Failed to fetch filings');
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteFiling = useCallback(async (caseId: string, filingId: string) => {
    setLoading(true);
    const results = await axios.delete(`filing-cases/${caseId}/filing-files/${filingId}`, {});
    setLoading(false);  
  },[]);

  const syncFilings = useCallback(async () => {
    setLoading(true);
    const {data} = await axios.post(`filing-cases/sync`);
    setLoading(false);  
    return data;
  },[]);

  // useEffect(() => {
  //   fetchFilings();
  // }, [fetchFilings]);

  return {
    fetchCaseMyFilings,
    fetchCaseFirmFilings,
    fetchCaseMyDraftFilings,
    fetchEServiceMyFilings,
    fetchEServiceReceivedFilings,
    fetchEServiceMyDraftFilings,
    fetchFilingById,
    loading,
    error,
    pagination,
    deleteFiling,
    syncFilings
  };
};

export default useFilings;
