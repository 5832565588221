import { useCallback, useState } from "react";
import { useAuth } from "./auth";
import { HttpPaginationDataResponse, PaginationDataResponse, paginationEmpty, parseResponse } from "../components/table/AgTable";
import { PaymentAccount } from "./usePaymentAccount";
import { Firm } from "./useAccount";

export interface GlobalPaymentAccountFilters extends PaginationDataResponse {
};

const useGlobalPaymentAccount = () => {
  const { axios } = useAuth({ middleware: 'auth' })
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [pagination, setPagination] = useState<GlobalPaymentAccountFilters>(paginationEmpty);

  const fetchGlobalPaymentAccounts = useCallback(async (options?: GlobalPaymentAccountFilters) => {
    setLoading(true);
    setError(null);
    try {
      const params: any = {
        sort_by: "id_desc",
        items_per_page: options?.perPage || pagination.perPage,
        page: options?.currentPage || pagination.currentPage
      };
      delete params.currentPage

      const response: HttpPaginationDataResponse = (await axios.get('global-payment-accounts', { params })).data;

      setPagination({ ...pagination, ...options, ...parseResponse(response) });

      setLoading(false);
    } catch (err) {
      setError('Failed to fetch Global Payment Accounts');
      setLoading(false);
    }
  }, []);

  const createGlobalPaymentAccount = useCallback((account: PaymentAccount) => {
    return axios.post('global-payment-accounts', account);
  }, []);

  const deleteGlobalPaymentAccount = useCallback((account: PaymentAccount) => {
    return axios.delete(`global-payment-accounts/${account.id}`);
  }, []);

  const updateFirmGlobalPaymentAccounts = useCallback((firm: Firm, data: any) => {
    return axios.post(`firms/${firm.id}/global-payment-accounts`, data);
  }, []);

  return {
    fetchGlobalPaymentAccounts,
    createGlobalPaymentAccount,
    deleteGlobalPaymentAccount,
    updateFirmGlobalPaymentAccounts,
    pagination
  };
};

export default useGlobalPaymentAccount;
