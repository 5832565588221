import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./styles.css";
import { SizeColumnsToContentStrategy, SizeColumnsToFitGridStrategy, SizeColumnsToFitProvidedWidthStrategy } from 'ag-grid-community';
import AgCustomRemotePaginator from './AgCustomRemotePaginator';
import { CropLandscapeOutlined } from '@mui/icons-material';

export type RemoteFetchMethod = (options: PaginationDataRequest) => Promise<void>

interface TableProps {
  data: Array<any>
  colDef: Array<any>
  // setPagination?: Function
  pagination: PaginationDataResponse
  fetchMethod?: RemoteFetchMethod
  customStyle?: string
};

export interface PaginationDataRequest {
  currentPage: number
  perPage: number
  // orderColumn?: number
  // orderDir?: number
};

export interface PaginationDataResponse {
  currentPage: number
  data: any[]
  from: number
  to: number
  lastPage: number
  perPage: number
  total: number
};

export interface HttpPaginationDataResponse { //json from the API
  current_page: number
  data: any[]
  from: number
  to: number
  last_page: number
  per_page: number
  total: number
};

export interface PaginationData {
  currentPage: number
  pageSize: number
  total: number
  from: number
  to: number
  isLastPage: boolean
  sortBy?: string
  pageSizeSelector?: number[]
};

export function parseResponse(response: HttpPaginationDataResponse): PaginationDataResponse {
  const data: PaginationDataResponse = {
    currentPage: response.current_page,
    data: response.data,
    from: response.from,
    to: response.to,
    lastPage: response.last_page,
    perPage: response.per_page,
    total: response.total,
  }
  return data;
}

export const paginationEmpty: PaginationDataResponse = {
  total: 0,
  data: [],
  from: 0,
  to: 0,
  perPage: 5,
  currentPage: 1,
  lastPage: 1
}

const AgTable = ({ data, colDef, pagination, fetchMethod, customStyle }: TableProps) => {
  const gridRef: RefObject<AgGridReact> = useRef<AgGridReact>(null);
  const [colDefs] = useState(colDef);
  
  useEffect(() => {
    //redraw columns on resize
    const resizeColumns = () => {
      if (gridRef.current) {
        gridRef.current.api.sizeColumnsToFit();
      }
    };
    window.addEventListener('resize', resizeColumns);
    return () => window.removeEventListener('resize', resizeColumns);
  }, []);

  const autoSizeStrategy = useMemo<
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy
  >(() => {
    return {
      type: "fitGridWidth",
    };
  }, []);

  const onPaginationChanged = useCallback((params: any) => {

  }, [pagination]);

  const localFetchMethod = async (options: PaginationDataRequest): Promise<void> => {
    if (!fetchMethod) {
      return;
    }
    //#TODO: add order options
    fetchMethod({ ...options });
  }

  return (
    <div className={`ag-theme-quartz w-full flex flex-col ${customStyle ?? 'ag-theme-my-fillings'}`}>
      <AgGridReact
        ref={gridRef}
        rowData={data}
        columnDefs={colDefs}
        autoSizeStrategy={autoSizeStrategy}
        pagination={true}
        paginationPageSize={pagination?.perPage}
        suppressPaginationPanel={true}
        onPaginationChanged={onPaginationChanged}
        domLayout="autoHeight"
        onGridReady={() => gridRef.current?.api.sizeColumnsToFit()}
      />
      {
        // !fetchMethod && <AgCustomPaginator
        //   agRef={gridRef}
        //   paginationData={pagination}
        //   setPaginationData={pagination}
        // />
      }
      {
        fetchMethod && <AgCustomRemotePaginator
          agRef={gridRef}
          pagination={pagination}
          fetchMethod={localFetchMethod}
        />
      }
    </div>
  );
};

export default AgTable;
