import { ReactElement, RefObject, useEffect, useMemo, useRef, useState } from "react";
import FormLabel from "../../../../components/forms/FormLabel";
import FormInput from "../../../../components/forms/FormInput";
import { useFormik } from "formik";
import { FormButton } from "../../../../components/forms/FormButton";
import FormTextarea from "../../../../components/forms/FormTextarea";
import useRoles, { Permission, Role } from "../../../../hooks/useRoles";
import { AgGridReact } from "ag-grid-react";
import { SizeColumnsToContentStrategy, SizeColumnsToFitGridStrategy, SizeColumnsToFitProvidedWidthStrategy } from "ag-grid-community";
import Title from "../../../../components/Title";
import * as yup from 'yup';
import { Checkbox } from "@mui/material";
import useAdmins from "../../../../hooks/useAdmins";

interface SuperAdminFormProps {
  afterSave?: Function
}

const CreateSuperAdminForm = ({ afterSave }: SuperAdminFormProps): ReactElement => {
  const gridRef: RefObject<AgGridReact> = useRef<AgGridReact>(null);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const { createAdmin } = useAdmins();

  const initialValues: any = {
    email: ''
  };

  const formik = useFormik({
    initialValues,
    validationSchema: () => {
      return yup.object().shape({
        email: yup.string().required("Please enter the user email")
      });
    },
    onSubmit: async (values, { setSubmitting }) => {
      await createAdmin(values.email);
      formik.resetForm();
      afterSave && afterSave();
    }
  });

  return <form onSubmit={formik.handleSubmit} className="text-sm max-w-2xl">
    <FormLabel mandatory>
      Enter Email
    </FormLabel>
    <div className="flex gap-4">
      <div className="flex-1">
        <FormInput
          formik={formik}
          name="email"
        />
      </div>
      <div className="flex-initial w-42">
        <FormButton className="m-auto w-full">
          Add New Admin
        </FormButton>
      </div>
    </div>
  </form>
};

export default CreateSuperAdminForm;