import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import FormInput from '../../../../components/forms/FormInput';
import FormLabel from '../../../../components/forms/FormLabel';
import FormSelect from '../../../../components/forms/FormSelect';
import { useFormik } from 'formik';
import { Button } from '@mui/material';
import { FormButton } from '../../../../components/forms/FormButton';
import { useEffect, useState } from 'react';
import axios from 'axios';
import * as yup from 'yup';

const initialValues: any = {
    card_holder_name  : '',
    address1: '',
    address2: '',
    card_state: '',
    card_city: '',
    card_zipcode: ''
};

interface StripeNewCardFormProps {
    onCancel: Function;
    onCreate: Function;
};

interface NewCardData {
    card_company?: string;
    card_holder_name?: string | null;
    card_holder_address?: string,
    card_last4?: string,
    card_year?: number | null,
    card_month?: number | null,
    stripe_id?: string
}

const StripeNewCardForm = ({onCancel, onCreate}: StripeNewCardFormProps) => {
    const stripe = useStripe();
    const elements = useElements();
    
    // States Select
    const [states, setStates] = useState([]);
    const fetchStates = async () => {
        const { data } = await axios.get('/states');
        const values = data.map((value: any) => value.name);
        setStates(values);
    }  
    useEffect(() => {
        fetchStates();
    }, [])

    // Formik handler
    const stripeFormik = useFormik({
        initialValues,
        validationSchema: () => {
            const rules: any = {
                card_holder_name: yup.string().required("Card holder name missing"),
                address1: yup.string().required("Card holder address missing"),
                address2: yup.string().notRequired(),
                card_state: yup.string().required("State missing"),
                card_city: yup.string().required("City missing"),
                card_zipcode: yup.string().required("Zip code missing")
            };          

            return yup.object().shape(rules);
        },
        onSubmit: async (values, { setSubmitting, validateForm }) => {
            const newCardData: NewCardData = {
                card_company: '',
                card_holder_name: '',
                card_holder_address: '',
                card_last4: '',
                card_year: null,
                card_month: null,
                stripe_id: ''
            };
            if (stripe && elements) {
                const cardNumberElement = elements?.getElement("cardNumber");
                // @ts-ignore
                const response = await stripe?.createPaymentMethod({
                    type: 'card',
                    card: cardNumberElement,
                    billing_details: {
                        address: {
                            line1: values.address1,
                            line2: values.address2,
                            city: values.card_city,
                            state: values.card_state,
                            postal_code: values.card_zipcode,
                            country: 'US'
                        },
                        name: values.card_holder_name
                    }
                });
                if (!response.error) {
                    const address = `${response?.paymentMethod?.billing_details?.address?.line1}, ${response?.paymentMethod?.billing_details?.address?.city}, ${response?.paymentMethod?.billing_details?.address?.postal_code}`
                    newCardData.card_company = response?.paymentMethod?.card?.brand;
                    newCardData.card_holder_name = response?.paymentMethod?.billing_details?.name;
                    newCardData.card_holder_address = address;
                    newCardData.card_last4 = response?.paymentMethod?.card?.last4;
                    newCardData.card_year = response?.paymentMethod?.card?.exp_year;
                    newCardData.card_month = response?.paymentMethod?.card?.exp_month;
                    newCardData.stripe_id = response?.paymentMethod?.id;
                    onCreate(newCardData);
                    
                }
                
            }
        }
        
    });

    // Stripe errors handlers
    const [cardNumberError, setCardNumberError] = useState();
    const handleCardNumberChange = (e: any) => {
      setCardNumberError(e.error?.message);    
    };
    const [cardExpirationError, setCardExpirationError] = useState();
    const handleCardExpirationChange = (e: any) => {
        setCardExpirationError(e.error?.message);    
    };
    const [cardCVVError, setCardCVVError] = useState();
    const handleCardCVVChange = (e: any) => {
        setCardCVVError(e.error?.message);    
    };

    const handleCancel = () => {
        // Clear all the values from the form
        elements?.getElement("cardNumber")?.clear();
        elements?.getElement("cardCvc")?.clear();
        elements?.getElement("cardExpiry")?.clear();
        stripeFormik.resetForm();

        // call the callback to close the window
        onCancel();
    };

    return (
        <form onSubmit={stripeFormik.handleSubmit} className="text-sm">
            <p className="block font-normal text-base-gray mb-6">
                Enter the information for the new payment account. 
                The fields marked with a red asterisk (<span className="text-red-700 font-bold relative">*</span>) are required fields.
            </p>
            <span className="text-base-blue text-base font-bold mb-4">Cardholder Information</span>
            <p className="block font-normal text-base-gray mb-6">
                Enter the information as if appears on the Cardhollder Account.
            </p>
            <div className="mb-4">
                <FormLabel mandatory>
                    Credit Card Number
                </FormLabel>
                <CardNumberElement 
                    className="p-2 w-full border rounded-md tracking-wider hover:border-gray-300 focus:outline-none focus:border-gray-300" 
                    onChange={handleCardNumberChange}
                />
                {cardNumberError && <p className={`form-error text-red-700 mt-2`}>{cardNumberError}</p> }
            </div>
            <div className="mb-4 grid grid-cols-2 grid-rows-1 gap-4">
                <div>
                    <FormLabel mandatory>
                        Expiration date
                    </FormLabel>
                    <CardExpiryElement 
                        className="p-2 w-full border rounded-md tracking-wider hover:border-gray-300 focus:outline-none focus:border-gray-300"
                        onChange={handleCardExpirationChange}
                    />
                    {cardExpirationError && <p className={`form-error text-red-700 mt-2`}>{cardExpirationError}</p> }
                </div>
                <div>
                    <FormLabel mandatory>
                        CVV
                    </FormLabel>
                    <CardCvcElement
                        className="p-2 w-full border rounded-md tracking-wider hover:border-gray-300 focus:outline-none focus:border-gray-300"
                        onChange={handleCardCVVChange}
                    />
                    {cardCVVError && <p className={`form-error text-red-700 mt-2`}>{cardCVVError}</p> }
                </div>
            </div>
            <div className="mb-4">
                <FormLabel mandatory>
                    Name on Card
                </FormLabel>
                <FormInput
                    formik={stripeFormik}
                    name="card_holder_name"
                    placeholder="Please enter the cardholde's name"
                />
            </div>
            <div className="mb-4">
                <FormLabel mandatory>
                    Addres Line 1
                </FormLabel>
                <FormInput
                    formik={stripeFormik}
                    name="address1"
                    placeholder="Please enter the cardholde's address"
                />
                
            </div>
            <div className="mb-4">
                <FormLabel>
                    Addres Line 2
                </FormLabel>
                <FormInput
                    formik={stripeFormik}
                    name="address2"
                    placeholder="Please enter the cardholde's address"
                />
            </div>
            <div className="mb-6 grid grid-cols-3 grid-rows-1 gap-2">
                <div>
                    <FormLabel mandatory>
                        State
                    </FormLabel>
                    <FormSelect
                        options={states}
                        formik={stripeFormik}
                        name="card_state"
                        placeholder="Choose an option"
                    />
                </div>
                <div>
                    <FormLabel mandatory>
                        City
                    </FormLabel>

                    <FormInput
                        formik={stripeFormik}
                        name="card_city"
                        placeholder="Enter the city"
                    />
                </div>
                <div>
                    <FormLabel mandatory>
                        ZIP Code
                    </FormLabel>
                    <FormInput
                        formik={stripeFormik}
                        name="card_zipcode"
                        placeholder="Enter ZIP Code"
                    />
                </div>
            </div>
            <div className="grid grid-rows-1 grid-cols-2 text-center gap-4">
                <Button
                    variant="outlined"
                    sx={{
                    border: "solid 1px #5261A0",
                    backgroundColor: "white",
                    // marginBottom: "16px",
                    color: "#5261A0",
                    paddingX: "16px",
                    textTransform: "none",
                    "&:hover": {
                        backgroundColor: "#5261A0",
                        color: "white",
                    },
                    margin: "auto",
                    width: '100%'
                    }}
                    onClick={handleCancel}
                    
                >
                    Return to Account Information
                </Button>
                <FormButton
                    // variant="contained"
                    // color="primary"
                    // sx={{
                    //     backgroundColor: "#5261A0",
                    //     textTransform: "capitalize",
                    //     fontSize: 14,
                    //     "&:hover": { backgroundColor: "#3e4d7a" },
                    // }}
                    type="submit"
                >
                Add Payment Account
                </FormButton>
            </div>
        </form>
    );
};

export default StripeNewCardForm;