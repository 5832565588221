import { Button } from "@mui/material";
import useFilings from "../../../hooks/useFilings";
import DialogConfirm from "../../../components/dialogs/DialogConfirm";
import { useState } from "react";
import Dialog from "../../../components/dialogs/Dialog";

interface SyncFilingsButton {
  afterSync?: Function
}

const SyncFilingsButton = ({ afterSync, ...props }: SyncFilingsButton) => {
  const { syncFilings } = useFilings();
  const [dialogOpen, setDialogOpen] = useState(false)
  const [nFilingsSync, setNFilingsSync] = useState<false|number>(false)

  const onSyncFilings = async () => {
    try {
      const result:any = await syncFilings();
      setNFilingsSync(result.new);
      afterSync && afterSync();
    } catch (e: any) { }
    setDialogOpen(false);
  }

  return <>
    <Button
      {...props}
      variant="outlined"
      sx={{
        border: "solid 1px #5261A0",
        backgroundColor: "white",
        // marginBottom: "16px",
        color: "#5261A0",
        paddingX: "32px",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#5261A0",
          color: "white",
        },
      }}
      onClick={() => setDialogOpen(true)}
    >
      Sync Filings
    </Button>
    <DialogConfirm
      title="Sync Filings"
      confirm={onSyncFilings}
      cancel={() => setDialogOpen(false)}
      open={dialogOpen}
      >
      Would you like to sync your/your firm's e-filings sent through alternative providers
    </DialogConfirm>
    <Dialog
      title="Sync Filings"
      open={nFilingsSync !== false}
      onCancel={() => setNFilingsSync(false)}
    >
      {`${nFilingsSync}`} filings were synchronized.
    </Dialog>
  </>
};

export default SyncFilingsButton;
