import { Box, Button, Tooltip, Typography } from "@mui/material";
import CustomTable from "../../../components/table/CustomTable";
import AddPaymentDialog from "../../../components/dialogs/DialogCreatePaymentAccount";
import ViewPaymentAccountDialog from "../../../components/dialogs/DialogViewPaymentAccount";
import AgTable, { paginationEmpty } from "../../../components/table/AgTable";
import { DeleteOutlined, EditOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import usePaymentAccounts, { PaymentAccount } from "../../../hooks/usePaymentAccount";
import { useSelector } from "react-redux";
import DialogDelete from "../../../components/dialogs/DialogDelete";

const MFRPaymentAccounts = (): React.ReactElement => {

    const [showAddPayment,setShowAddPayment] = useState(false);
    const [showViewPayment,setShowViewPayment] = useState(false);
    const [showConfirmDelete,setShowConfirmDelete] = useState(false);
    const [data, setData] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState<PaymentAccount | null>(null);
    const [idToDelete, setIdToDelete] = useState<number| null>(null);
    
    // Hooks
    const {
        paymentAccounts,
        loading,
        fetchPaymentAccounts,
        createPaymentAccount,
        deletePaymentAccount,
        getPaymentAccountById,
    } = usePaymentAccounts();

    // Handles
    // Add
    function handleAddPaymentOpen(): void {
        setShowAddPayment(true);
    }
    function handleAddPaymentClose(): void {
        setShowAddPayment(false);
    }
    const handleCreateAccount = (data: any) => {
        // console.log('card', data);
        setShowAddPayment(false);
    }
    // View
    const handleViewPaymentShow = async (row: any) => {
        try {
            const account = getPaymentAccountById(row.id);
            if (account) {
                setSelectedAccount(account);
                setShowViewPayment(true);
            }
        } catch (e) {
            
        }
    }

    function handleViewPaymentClose(): void {
        setShowViewPayment(false);
    }
    // Delete
    const handleShowDeletePayment = (row: any) => {
        setIdToDelete(row.id);
        setShowConfirmDelete(true);
    };

    const confirmDelete = async () => {
        try {
            if (idToDelete) {
                await deletePaymentAccount(idToDelete);
                setIdToDelete(null);
                setShowConfirmDelete(false);
                fetchPaymentAccounts(10, 1, 0, 'stripe'); // Refresh the list
            }
        } catch (error) {
            console.error('Error deleting account:', error);
            // You might want to show an error message to the user here
        }
    }

    const handleCancelDelete= async () => {
        setIdToDelete(null);
        setShowConfirmDelete(false);
    }

    // Edit
    function handleEditFiling(id: number): void {
        throw new Error("Function not implemented.");
    }

    // Table
    const actions = (id: number) => {
        const baseActions = [
            {
                label: 'View',
                onClick: handleViewPaymentShow,
                icon: <VisibilityOutlined className="text-base-blue" />,
            },
            {
                label: 'Delete',
                onClick: handleShowDeletePayment,
                icon: <DeleteOutlined className="text-base-blue" />,
            },
            {
                label: 'Edit',
                onClick: handleEditFiling,
                icon: <EditOutlined className="text-base-blue" />,
            }
        ];
        return baseActions;
    };
    const colDef: Array<Object> = [
        { field: 'name', headerName: 'Account Name', flex: 1 },
        { field: 'type', headerName: 'Account Type', flex: 1 },
        {
            field: 'filing_case.filing_status.code',
            headerName: 'Actions',
            maxWidth: 120,
            cellRenderer: (data: any) => <div className="flex space-x-2">
            {actions(data.value).map((action: any, index: number) => (
                <Tooltip key={index} title={action.label} arrow>
                <button onClick={() => action.onClick(data.data)}>
                    {action.icon}
                </button>
                </Tooltip>
            ))}
            </div>
        },
    ];

    // Fetch accounts on mount
    useEffect(() => {
        fetchPaymentAccounts(10, 1, 0, 'stripe');
    }, []);

    useEffect(() => {
        if (paymentAccounts && paymentAccounts.length >= 1) {
            const results = paymentAccounts.map((item: any) => ({...item, actions: actions(item.id)}));
            setData(results);
        } else {
            setData([]);
        }
    }, [paymentAccounts]);

    return (
        <>
            <Box display="flex" justifyContent="end" mb={2} mx={0} gap={2}>
                <Button
                    variant="outlined"
                    sx={{
                    border: "solid 1px #5261A0",
                    backgroundColor: "white",
                    // marginBottom: "16px",
                    color: "#5261A0",
                    paddingX: "32px",
                    textTransform: "none",
                    "&:hover": {
                        backgroundColor: "#5261A0",
                        color: "white",
                    },
                    }}
                    onClick={() => {}}
                >
                    Sync
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        backgroundColor: "#5261A0",
                        textTransform: "capitalize",
                        fontSize: 14,
                        "&:hover": { backgroundColor: "#3e4d7a" },
                    }}
                    onClick={handleAddPaymentOpen}
                >
                Add New Payment Account
                </Button>
            </Box>

            <Typography variant="h6" sx={{ fontSize: 16, marginBottom: 2 }}>
                Your Payment Accounts
            </Typography>
            <AgTable
                colDef={colDef}
                data={data}
                pagination={paginationEmpty}
                customStyle="ag-theme-payments"
            />

             <AddPaymentDialog
                open={showAddPayment}
                onClose={handleAddPaymentClose}
                onSave={handleCreateAccount}
                type="stripe"
            />
            
            <ViewPaymentAccountDialog
                open={showViewPayment}
                onClose={handleViewPaymentClose}
                account={selectedAccount}
            />

            <DialogDelete
                // title="Are you sure you want to delete this filing?"
                open={showConfirmDelete}
                cancel={handleCancelDelete}
                confirm={confirmDelete}
            >
                <p>Are you sure you want to <b>delete</b> this payment account?</p>
            </DialogDelete>
        </>
    );
}

export default MFRPaymentAccounts;