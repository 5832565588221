import { ReactElement, useEffect } from "react";
import { useFormik } from "formik";
import { FormButton } from "../../../../components/forms/FormButton";
import useRoles, { Role } from "../../../../hooks/useRoles";
import * as yup from 'yup';
import useAdmins from "../../../../hooks/useAdmins";
import RoundedBox from "../../../../components/layout/RoundedBox";
import FormSelectMultiple from "../../../../components/forms/FormSelectMultiple";
import { makeName } from "../../../../utils/utils";
import { User } from "../../../../hooks/useUsers";

interface SuperAdminFormProps {
  admin: User
  afterSave?: Function
}

const EditSuperAdminForm = ({ admin, afterSave }: SuperAdminFormProps): ReactElement => {
  const { editAdmin } = useAdmins();
  const { pagination, fetchRoles } = useRoles()

  useEffect(() => {
    fetchRoles({...pagination, currentPage: 1, perPage: 10000});
  }, []);

  useEffect(() => {
    const roles = (admin.admin_roles ?? []).map((role: Role) => role.id);
    formik.setValues({id: admin.id, admin_roles: roles})
  }, [admin]);

  const initialValues: any = {
    id: 0,
    admin_roles: []
  };

  const formik = useFormik({
    initialValues,
    validationSchema: () => {
      return yup.object().shape({
        // email: yup.string().required("Please enter the user email")
      });
    },
    onSubmit: async (values, { setSubmitting }) => {
      await editAdmin(values);
      afterSave && afterSave();
    }
  });

  if(!admin) {
    return <></>
  }

  return <form onSubmit={formik.handleSubmit} className="text-sm max-w-2xl">
    <div className="mb-4">
      <div>
        <b>User: </b><span>{makeName(admin)}</span>
      </div>
      <div>
        <b>Email: </b><span>{admin.email}</span>
      </div>
    </div>
    <RoundedBox className="bg-background-overlay-blue">
      <FormSelectMultiple options={pagination.data} formik={formik} name="admin_roles" styles={{background: 'none', width: '500px', border: 'none'}}/>
    </RoundedBox>
    <p className="form-error text-red-700 mt-2 text-center mb-6">
      Note: The changes will become effective once the user logs in again.
    </p>
    <div className="text-center">
      <FormButton className="w-56" type="submit">Save Changes</FormButton>
    </div>
  </form>
};

export default EditSuperAdminForm;