import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RoundedBox from '../../../components/layout/RoundedBox';
import BackArrow from '../../../components/BackArrow';
import PrivateLayout from '../../../components/layout/PrivateLayout';
import CaseUpdates from '../../../components/filings/details/CaseUpdates';
import FilingInformation, { FilingInfo } from '../../../components/filings/details/FilingInformation';
import useReports from '../../../hooks/useReports';
import dayjs from 'dayjs';

// reports mock data
const mockReportData = {
  filing_case: {
    title: "Smith vs. Johnson",
    location: {
      name: "Supreme Court of California"
    },
    filing_status: {
      short_name: "Accepted"
    },
    filing_case_envelope: {
      envelope_id: "ENV-2024-001",
      provider_service_fees: "$25.00",
      provider_tax_fees: "$2.50",
      court_service_fees: "$150.00",
      service_tax_fees: "$15.00",
      filing_service_fees: "$50.00",
      court_filing_fees: "$435.00",
      court_case_fees: "$100.00",
      convenience_fees: "$10.00",
      total_fees: "$787.50"
    },
    case_type: {
      case_category: {
        name: "Civil"
      },
      name: "Contract Dispute"
    },
    payment_account: {
      name: "Doe Legal Services Account",
      type: "N/A"
    }
  },
  filing_code: {
    name: "Initial Filing"
  },
  description: "Initial complaint filing for contract dispute case",
  client_number: "CLT-2024-001",
  user: {
    first_name: "John",
    last_name: "Doe",
    email: "john.doe@example.com",
    firm: {
      name: "Doe Legal Services",
      address_1: "123 Legal Street",
      city: "Los Angeles",
      state: "CA",
      zip: "90210",
      phone: "(555) 123-4567"
    }
  },
  filing_documents: [
    {
      id: "doc1",
      name: "complaint.pdf",
      size: 1024000,
      description: "Initial complaint document"
    },
    {
      id: "doc2",
      name: "evidence.pdf",
      size: 2048000,
      description: "Supporting documentation"
    }
  ]
};

// ---------------

interface ReportDetailsProps {
  caseNumber: string;
  acceptedAt: string;
  updates: { status: string; time: string }[];
  location: string;
  court: string;
  envelopeDraft: string;
  filingType: string;
  submissionTime: string;
  description: string;
}
interface StatusHistory {
  status: string;
  shortName: string;
  date: string;
  time: string;
  color: string;
}

const ReportDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { loading, fetchReportById } = useReports();
  const [reportInfo, setReportInfo] = useState<any>();
  const [statusHistory, setStatusHistory] = useState<StatusHistory[]>();

  useEffect(() => {
    if (!id) {
      return;
    }
    const fetchReport = async () => {
      const result = await fetchReportById(id);
      if (!result) {
        return;
      }
      setReportInfo(result);
    }
    fetchReport();
  }, [id, fetchReportById]);

  const handleGoBack = () => {
    navigate('/my-reports')
  }

  if(!reportInfo || loading) {
    return <div></div>
  }

  return (
    <PrivateLayout>
        <RoundedBox className="bg-white py-6 mb-4">
          <BackArrow onClick={handleGoBack}>
            <span className="tracking-wider">Back</span>
          </BackArrow>
        </RoundedBox>
        <CaseUpdates
          caseNumber={reportInfo.filing_case.docket_id}
          statuses={reportInfo.filing_case.filing_statuses}
        />
        {/* <FilingInformation filingInfo={reportInfo} /> */}
        <FilingInformation filingInfo={mockReportData} />
    </PrivateLayout>
  );
};

export default ReportDetails;
