import { ReactElement, useEffect, useState } from "react";
import PrivateLayout from "../../../components/layout/PrivateLayout";
import RoundedBox from "../../../components/layout/RoundedBox";
import Title from "../../../components/Title";
import AgTable, { PaginationDataRequest } from "../../../components/table/AgTable";
import { Tooltip } from "@mui/material";
import { DeleteOutlineOutlined, EditOutlined, Visibility } from "@mui/icons-material";
import { useMessageBoard } from "../../../components/assets/MessageBoard";
import { User } from "../../../hooks/useUsers";
import useFirms from "../../../hooks/useFirms";
import FilterSearchBar from "../../../components/FilterSearchBar";
import useGlobalPaymentAccount from "../../../hooks/useGlobalPaymentAccount";
import { FormButton } from "../../../components/forms/FormButton";
import ViewPaymentAccountDialog from "../../../components/dialogs/DialogViewPaymentAccount";
import { PaymentAccount } from "../../../hooks/usePaymentAccount";
import AddPaymentDialog from "../../../components/dialogs/DialogCreatePaymentAccount";
import DialogDelete from "../../../components/dialogs/DialogDelete";
import Dialog from "../../../components/dialogs/Dialog";
import { Firm } from "../../../hooks/useAccount";
import ConfigureFirmGlobalPaymentAccountsForm from "./components/ConfigureFirmGlobalPaymentAccountsForm";

interface ManageSuperAdminProps {

}

const GlobalPaymentAccounts = ({ }: ManageSuperAdminProps): ReactElement => {

  const globalPaymentAccounts = useGlobalPaymentAccount();
  const fetchGlobalPaymentAccounts = globalPaymentAccounts.fetchGlobalPaymentAccounts;
  const paginationGlobalPaymentAccounts = globalPaymentAccounts.pagination;
  const deleteGlobalPaymentAccount = globalPaymentAccounts.deleteGlobalPaymentAccount;

  const firms = useFirms();
  const fetchFirms = firms.fetchFirms;
  const paginationFirms = firms.pagination;

  const [newAccountDialogOpen, setNewAccountDialogOpen] = useState(false);
  const [viewAccount, setViewAccount] = useState<PaymentAccount>();
  const [deleteAccount, setDeleteAccount] = useState<PaymentAccount>();
  const { addMessageSuccess, addMessageError } = useMessageBoard();
  
  const [selectedFirmGP, setSelectedFirmGP] = useState<Firm>();
  const [selectedFirmIP, setSelectedFirmIP] = useState<Firm>();

  useEffect(() => {
    fetchGlobalPaymentAccounts();
    fetchFirms();
  }, []);

  const actions = () => {
    return [{
      label: 'View',
      onClick: (account: PaymentAccount) => setViewAccount(account),
      icon: <Visibility className="text-base-blue" />,
    }, {
    //   label: 'Edit',
    //   onClick: (account: PaymentAccount) => setEditAccount(account),
    //   icon: <EditOutlined className="text-base-blue" />,
    // }, {
      label: 'Delete',
      onClick: handleDeleteGlobalPaymentAccount,
      icon: <DeleteOutlineOutlined className="text-base-blue" />,
    }];
  }

  const colDefGlobalPaymentAccount: Array<Object> = [
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'id',
      headerName: 'Actions',
      maxWidth: 120,
      cellRenderer: (data: any) => <div className="flex space-x-2">
        {
          actions().map((action: any, index: number) => (
            <Tooltip key={index} title={action.label} arrow>
              <button onClick={() => action.onClick(data.data)}>
                {action.icon}
              </button>
            </Tooltip>
          ))
        }
      </div>
    },
  ];

  const colDefFirms: Array<Object> = [
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'id', headerName: 'Global Payment', maxWidth: 200, cellRenderer: (data: any) => <div className="flex space-x-2">
        <a onClick={() => setSelectedFirmGP(data.data) } className="text-blue-600 hover:underline cursor-pointer">
          Configure
        </a>
      </div>
    },
    {
      field: 'id', headerName: 'Invoice Preference', maxWidth: 200, cellRenderer: (data: any) => <div className="flex space-x-2">
        <a onClick={() => setSelectedFirmIP(data.data) } className="text-blue-600 hover:underline cursor-pointer">
          Change Invoice Preference
        </a>
      </div>
    }
  ];

  const handleCreateAccount = async (account: PaymentAccount) => {
    try {
      setNewAccountDialogOpen(false);
      fetchGlobalPaymentAccounts({ ...paginationGlobalPaymentAccounts, currentPage: 1 })
    } catch (error) {
      console.error('Error creating account:', error);
      // You might want to show an error message to the user here
    }
  };

  const handleDeleteGlobalPaymentAccount = (account: PaymentAccount) => {
    setDeleteAccount(account);
  };

  const confirmDelete = async () => {
    if (!deleteAccount) {
      return;
    }
    await deleteGlobalPaymentAccount(deleteAccount);
    fetchGlobalPaymentAccounts({ ...paginationGlobalPaymentAccounts, currentPage: 1 });
    setDeleteAccount(undefined);
    addMessageSuccess('Global Payment Account deleted');
  }

  const onPaginationglobalPaymentAccountsChange = (options: PaginationDataRequest) => {
    return fetchGlobalPaymentAccounts({ ...paginationGlobalPaymentAccounts, ...options });
  }

  const onPaginationFirmsChange = (options: PaginationDataRequest) => {
    return fetchFirms({ ...paginationFirms, ...options });
  }

  const onSearchFirmsChange = (search: string) => {
    return fetchFirms({ ...paginationFirms, search });
  }

  const afterSaveGP = () => {
    fetchFirms({ ...paginationFirms, currentPage: 1 });
    setSelectedFirmGP(undefined)
  }

  return <PrivateLayout>
    <RoundedBox className="bg-white">
      <div className="py-6">
        <div className="flex mb-6">
          <div className="flex-1">
            <Title>
              Global Payment Accounts
            </Title>
          </div>
          <div className="flex-initial">
            <FormButton onClick={() => setNewAccountDialogOpen(true)} className="w-full text-center font-bold">
              Add new Global Payment Account
            </FormButton>
          </div>
        </div>
        <AgTable
          data={paginationGlobalPaymentAccounts.data}
          colDef={colDefGlobalPaymentAccount}
          pagination={paginationGlobalPaymentAccounts}
          fetchMethod={onPaginationglobalPaymentAccountsChange}
        />
      </div>
      <hr />
      <div className="py-6">
        <div className="mb-6">
          <Title>
            Firms
          </Title>
          <FilterSearchBar onChange={onSearchFirmsChange} placeholder="Search by Firm name..." />
        </div>
        <AgTable
          data={paginationFirms.data}
          colDef={colDefFirms}
          pagination={paginationFirms}
          fetchMethod={onPaginationFirmsChange}
        />
      </div>
    </RoundedBox>
    <AddPaymentDialog
      open={newAccountDialogOpen}
      onClose={() => setNewAccountDialogOpen(false)}
      onSave={handleCreateAccount}
      isGlobal={true}
    />
    <ViewPaymentAccountDialog
      open={!!viewAccount}
      onClose={() => setViewAccount(undefined)}
      account={viewAccount}
    />
    {
      selectedFirmGP && <Dialog
        buttons={[]}
        open={!!selectedFirmGP}
        onCancel={() => setSelectedFirmGP(undefined)}
        title="Configure Pyment Account"
      >
        <ConfigureFirmGlobalPaymentAccountsForm firm={selectedFirmGP} afterSave={afterSaveGP} />
      </Dialog>
    }
    <DialogDelete
      open={deleteAccount}
      cancel={() => setDeleteAccount(undefined)}
      confirm={confirmDelete}
      title="Delete Global Payment Account"
    >
      Do you want to delete the Global Payment Account <b>{deleteAccount?.name}</b>?
    </DialogDelete>
  </PrivateLayout>
};

export default GlobalPaymentAccounts;