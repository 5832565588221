import { IMapping } from "../../../interfaces";
import { toUpperCaseFirst } from "../../../utils/utils";

export const colors: IMapping = {
  'mfr_draft': 'base-blue',
  'mfr_submitted': 'amber-400',
  'draft': 'sky-600',
  'failed': 'red-500',
  'submitting': 'sky-600',
  'submitted': 'amber-400',
  'accepted': 'lime-600',
  'rejected': 'red-500',
  'reviewing': 'base-blue',
  'under-review': 'base-blue',
  'reviewed': '[#FF8933]',
  'served': 'lime-600'
}

export const getKey = (status: any) => {
  if (typeof status == 'string') {
    return status.toLowerCase();
  }
  return status?.code;
}

export const getText = (status: any) => {
  if (typeof status == 'string') {
    return toUpperCaseFirst(status);
  }
  return status?.short_name;
}

const getColor = (status: string) => `${colors[getKey(status)] ?? 'gray-400'}`;

export const textColorByStatus = (status: string) => `text-${getColor(status)}`;

export const bgColorByStatus = (status: string) => `bg-${getColor(status)}`;

export const borderColorByStatus = (status: string) => `border-${getColor(status)}`;