import React, { useEffect, useState } from "react";
import { getText, bgColorByStatus, borderColorByStatus, textColorByStatus } from "../../../pages/my-filings/util";

interface StatusChipProps {
  status: string;
  type: 'borderLess' | 'bgLess';
}

const StatusChip = ({ status, type }: StatusChipProps) => {
  const [style, setStyle] = useState<string>('');

  useEffect(() => {
    if(!type || !status) {
      return;
    }
    switch (type) {
      case 'bgLess':
        setStyle(`bg-none border ${borderColorByStatus(status)} ${textColorByStatus(status)}`);
        break;
      case 'borderLess':
        setStyle(`${bgColorByStatus(status)} text-white`);
        break;
    };
  }, [type, status]);

  return <b className={`px-2 py-1 font-semibold rounded-xl ${style}`}>
    {getText(status)}
  </b>
};

export default StatusChip;