import { Box, Button, Grid, Typography } from '@mui/material';
import React, { MutableRefObject, useRef } from 'react';
import { FilingInfo } from './FilingInformation';
import { htmlToPdf, humanBytes, makeAddress, makeName, openNewTab } from '../../../utils/utils';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useAuth } from '../../../hooks/auth';

const RenderSection = ({ title, content }: { title: string, content: React.ReactNode }) => (
  <Box sx={{ mb: 3 }}>
    <Box sx={{
      backgroundColor: '#E7EDFF',
      px: 2,
      py: 1
    }}>
      <Typography sx={{ fontWeight: 500, fontSize: 14 }}>{title}</Typography>
    </Box>
    {content}
  </Box>
);

// Case Information Section
const CaseInfoSection = ({ filingInfo }: { filingInfo: any }) => (
  <Box sx={{
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  }}>
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Style|Case Name</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_case.title}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: '#F7F9FC'
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Location</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_case.location.name}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Status</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_case.filing_status.short_name}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: '#F7F9FC'
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Envelope</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_case.filing_case_envelope?.envelope_id}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Case Category</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_case.case_type.case_category.name}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: '#F7F9FC'
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Case Type</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_case.case_type.name}</Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

// Filing Information Section
const FilingInfoSection = ({ filingInfo }: { filingInfo: any }) => (
  <Box sx={{
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  }}>
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Type</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_code.name}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: '#F7F9FC'
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Submission Time</Typography>
          <Typography sx={{ fontSize: 14 }}></Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Description</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingInfo.description}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: '#F7F9FC'
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Client Matter N</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingInfo.client_number}</Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

// Filer Information Section
const FilerInfoSection = ({ filingInfo }: { filingInfo: any }) => (
  <Box sx={{
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  }}>
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filer Name</Typography>
          <Typography sx={{ fontSize: 14 }}>{makeName(filingInfo.user)}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: '#F7F9FC'
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Email</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingInfo.user.email}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Firm Name</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingInfo.user.firm.name}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: '#F7F9FC'
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Address</Typography>
          <Typography sx={{ fontSize: 14 }}>{makeAddress(filingInfo.user.firm)}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Phone Number</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingInfo.user.firm.phone}</Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

// Billing Information Section
const BillingInfoSection = ({ filingInfo }: { filingInfo: any }) => (
  <Box sx={{
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  }}>
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Account Name</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingInfo?.filing_case?.payment_account?.name}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: '#F7F9FC'
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Waiver Account</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingInfo?.filing_case?.payment_account?.type}</Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

// Documents Section
const DocumentsSection = ({ filingInfo }: { filingInfo: any }) => {

  const { axios } = useAuth();

  const getFile = async (filingDocument: any) => {
    const { data } = await axios.get(`filing-documents/${filingDocument.id}`);

    const link = document.createElement('a');
    link.href = data.tmp_url;
    link.download = filingDocument.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Grid item xs={12}>  {/* Full width */}
      <Box>
        <Box sx={{
          backgroundColor: '#E7EDFF',
          px: 2,
          py: 1
        }}>
          <Typography sx={{ fontWeight: 500, fontSize: 14 }}>Documents</Typography>
        </Box>

        <Box>
          {/* Header Row */}
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            backgroundColor: '#F7F9FC',
            py: 2,
            px: 2,
          }}>
            {/* <Typography sx={{ fontSize: 14, color: 'text.secondary', fontWeight: 'bold' }}>Document Type</Typography> */}
            <Typography sx={{ fontSize: 14, color: 'text.secondary', fontWeight: 'bold' }}>File Name</Typography>
            <Typography sx={{ fontSize: 14, color: 'text.secondary', fontWeight: 'bold', textAlign: 'right' }}>Size</Typography>
          </Box>

          {/* Document Rows */}
          {filingInfo.filing_documents.map((doc: any, index: number) => (
            <Box key={index} sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              py: 2,
              px: 2,
              backgroundColor: index % 2 === 0 ? 'white' : '#F7F9FC',
            }}>
              <Typography sx={{ fontSize: 14 }}>
                <a
                  className="text-sky-500 hover:text-sky-600 block cursor-pointer"
                  key={`file-${index}`}
                  onClick={() => getFile(doc)}
                >
                  {doc.name}
                </a>
              </Typography>
              <Typography sx={{ fontSize: 14, textAlign: 'right' }}>{humanBytes(doc.size)}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Grid>
  )
};

// Fee Information Section
const FilingFullDetails: React.FC<any> = ({ filingInfo }: { filingInfo: any }) => {

  const contentRef: MutableRefObject<HTMLElement | null> = useRef(null);

  const handlePrintReceipt = async () => {
    if (!contentRef?.current) {
      return;
    }
    openNewTab(await htmlToPdf(contentRef.current), 'receipt.pdf');
  };
console.log(filingInfo.filing_case.filing_case_envelope)
  if (!filingInfo) {
    return;
  }
  
  return <>
    <Box ref={contentRef}>
      <div className="flex flex-col gap-4 mb-4">
        <img
          src="/images/logo-header.svg"
          alt="Logo"
          className="object-contain w-56"
        />
        <h2 className="text-md font-bold space-x-2 uppercase text-black tracking-[.7em]">Filing Receipt</h2>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <RenderSection
            title="Case Information"
            content={<CaseInfoSection filingInfo={filingInfo} />}
          />
        </Grid>
        <Grid item xs={6}>
          <RenderSection
            title="Filing Information"
            content={<FilingInfoSection filingInfo={filingInfo} />}
          />
        </Grid>
        <Grid item xs={6}>
          <RenderSection
            title="Filer Information"
            content={<FilerInfoSection filingInfo={filingInfo} />}
          />
        </Grid>
        <Grid item xs={6}>
          <RenderSection
            title="Billing Information"
            content={<BillingInfoSection filingInfo={filingInfo} />}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DocumentsSection filingInfo={filingInfo} />
        </Grid>
        <Grid item xs={6}>
          <RenderSection
            title="Envelope Fee Information"
            content={
              <Box sx={{
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
              }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 2,
                    }}>
                      <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>My File Runner Service Fees</Typography>
                      <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_case.filing_case_envelope?.provider_service_fees}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 2,
                      backgroundColor: '#F7F9FC'
                    }}>
                      <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>My File Runner Tax Fees</Typography>
                      <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_case.filing_case_envelope?.provider_tax_fees}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            }
          />
        </Grid>
        <Grid item xs={6}>
          <RenderSection
            title="Filing Fee"
            content={
              <Box sx={{
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
              }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 2,
                    }}>
                      <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Court Service Fees</Typography>
                      <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_case.filing_case_envelope?.court_service_fees}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 2,
                    }}>
                      <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Service Tax Fees</Typography>
                      <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_case.filing_case_envelope?.service_tax_fees}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 2,
                    }}>
                      <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Service Fees</Typography>
                      <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_case.filing_case_envelope?.filing_service_fees}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 2,
                    }}>
                      <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Court Filing Fees</Typography>
                      <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_case.filing_case_envelope?.court_filing_fees}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 2,
                    }}>
                      <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Court Case Fees</Typography>
                      <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_case.filing_case_envelope?.court_case_fees}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 2,
                      backgroundColor: '#F7F9FC'
                    }}>
                      <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing & Services Fee</Typography>
                      <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_case.filing_case_envelope?.filing_service_fees}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 2,
                      backgroundColor: '#F7F9FC'
                    }}>
                      <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Convenience Fee</Typography>
                      <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_case.filing_case_envelope?.convenience_fees}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 2,
                    }}>
                      <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Court Fees (Total)</Typography>
                      <Typography sx={{ fontSize: 14 }}>{filingInfo.filing_case.filing_case_envelope?.total_fees}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            }
          />
        </Grid>
      </Grid>
    </Box>
    <div className="flex justify-center mb-4">
      <Button
        variant="contained"
        color="primary"
        sx={{
          backgroundColor: "#5261A0",
          textTransform: "capitalize",
          fontSize: 14,
          paddingX: 18,
          "&:hover": { backgroundColor: "#3e4d7a" },
        }}
        onClick={() => handlePrintReceipt()}
      >
        Print Receipt
      </Button>
    </div>
  </>
};

export default FilingFullDetails;
