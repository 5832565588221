import { Dayjs } from "dayjs";
import { useCallback, useState } from "react";
import { HttpPaginationDataResponse, PaginationDataResponse, paginationEmpty, parseResponse } from "../components/table/AgTable";
import { useAuth } from "./auth"

export interface ReportFilters extends HttpPaginationDataResponse {
  search?: string;

  statuses?: string[];
  envelope?: string;
  caseNumber?: string;
  clientMatterNumber?: string;
  attorneyId?: string;
  courtLocation?: string;

  dateFrom?: Dayjs | null;
  dateTo?: Dayjs | null;
}

export const statuses = {
  submitting: "Submitting",
  failed: "Failed",
  submitted: "Submitted",
  accepted: "Accepted",
  rejected: "Rejected",
};

// Mock data for development
const mockReportData = {
  id: '123',
  filing_case: {
    docket_id: 'CASE-2024-001',
    filing_statuses: [
      {
        name: 'Submitted',
        short_name: 'submitted',
        pivot: {
          created_at: '2024-02-06T12:00:00Z'
        },
        color: 'blue'
      },
      {
        name: 'Under Review',
        short_name: 'review',
        pivot: {
          created_at: '2024-02-06T12:30:00Z'
        },
        color: 'yellow'
      },
      {
        name: 'Accepted',
        short_name: 'accepted',
        pivot: {
          created_at: '2024-02-06T13:00:00Z'
        },
        color: 'green'
      }
    ]
  },
  case_name: 'Smith vs. Johnson',
  case_number: 'CASE-2024-001',
  court_name: 'Supreme Court of California',
  filing_status: 'Accepted',
  envelope: 'ENV-2024-001',
  case_category: 'Civil',
  case_type: 'Contract Dispute',
  filing_type: 'Initial Filing',
  filing_time: '2024-02-06T12:00:00Z',
  filing_description: 'Initial complaint filing for contract dispute case',
  client_matter_number: 'CLT-2024-001',
  filer_name: 'John Doe',
  email: 'john.doe@example.com',
  firm_name: 'Doe Legal Services',
  address: '123 Legal Street, CA 90210',
  phone_number: '(555) 123-4567',
  account_name: 'Doe Legal Services Account',
  waiver_account: 'N/A',
  documents: [
    {
      documentType: 'Complaint',
      fileName: 'complaint.pdf',
      description: 'Initial complaint document'
    },
    {
      documentType: 'Supporting Evidence',
      fileName: 'evidence.pdf',
      description: 'Supporting documentation'
    }
  ]
};

const useReports = () => {
  const { axios } = useAuth({ middleware: 'auth' });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [pagination, setPagination] = useState<ReportFilters>(paginationEmpty)

  const fetchReports = useCallback(async (url: string, filingTypes: string[], options?: ReportFilters) => {
    setLoading(true);
    setError(null);
    try {
      const params: any = {
        sort_by: "id_desc",
        items_per_page: options?.per_page || pagination.per_page,
        page: options?.current_page || pagination.current_page,
        filing_type: filingTypes
      };
      delete params.currentPage
      
      // Filter by statuses
      if (options?.statuses && options.statuses.length > 0) {
        params.statuses = options.statuses;
      }

      // Filter by search query
      if (options?.search) {
        params.search = options.search.toLowerCase();
      }

      // Filter by envelope
      if (options?.envelope) {
        params.envelope_number = options.envelope.toLowerCase();
      }

      // Filter by caseNumber
      if (options?.caseNumber) {
        params.case_number = options.caseNumber.toLowerCase();
      }

      // Filter by courtLocation
      if (options?.courtLocation) {
        params.court_location = options.courtLocation.toLowerCase();
      }

      // Filter by clientMatterNumber
      if (options?.clientMatterNumber) {
        params.client_matter_number = options.clientMatterNumber.toLowerCase();
      }

      // **Filter by date range**
      if (options?.dateFrom || options?.dateTo) {
        if (options?.dateFrom) {
          // isAfterFrom = lastUpdateDate.isAfter(options.dateFrom, 'day');
          params.date_from = options.dateFrom.format('YYYY-MM-DD');
        }
        if (options?.dateTo) {
          params.date_to =  options.dateTo.format('YYYY-MM-DD');
          // isBeforeTo =  lastUpdateDate.isBefore(options.dateFrom, 'day');
        }
      };

      const response: HttpPaginationDataResponse = (await axios.get(url, {params})).data;

      setPagination({...pagination, ...options, ...parseResponse(response)});

      setLoading(false);
    } catch (err) {
      setError('Failed to fetch filings');
      setLoading(false);
    }
  }, []);

  const fetchReportById = useCallback(async (id: string) => {
    setLoading(true);
    setError(null);
    
    try {
      // Simulate API delay
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Return mock data
      setLoading(false);
      return mockReportData;
    } catch (err) {
      setError('Failed to fetch report details');
      setLoading(false);
      return null;
    }
  }, []);

  return {
    fetchReports,
    fetchReportById,
    loading,
    error
  }
}

export default useReports;
