import { ReactElement, useEffect, useState } from "react";
import PrivateLayout from "../../../components/layout/PrivateLayout";
import RoundedBox from "../../../components/layout/RoundedBox";
import Title from "../../../components/Title";
import AgTable, { PaginationDataRequest } from "../../../components/table/AgTable";
import { Role } from "../../../hooks/useRoles";
import { Tooltip } from "@mui/material";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import DialogDelete from "../../../components/dialogs/DialogDelete";
import { useMessageBoard } from "../../../components/assets/MessageBoard";
import useAdmins from "../../../hooks/useAdmins";
import { User } from "../../../hooks/useUsers";
import { makeName } from "../../../utils/utils";
import CreateSuperAdminForm from "./components/CreateSuperAdminForm";
import EditSuperAdminForm from "./components/EditSuperAdminForm";
import Dialog from "../../../components/dialogs/Dialog";

interface ManageSuperAdminProps {

}

const IndexSuperAdmin = ({ }: ManageSuperAdminProps): ReactElement => {

  const { fetchAdmins, pagination, deleteAdmin } = useAdmins();
  const [admin, setAdmin] = useState<User>();
  const [adminDelete, setAdminDelete] = useState<User>();
  const { addMessageSuccess, addMessageError } = useMessageBoard();

  useEffect(() => {
    fetchAdmins();
  }, []);

  const handleDelete = (role: User) => {
    setAdminDelete(role);
  }

  const handleCancelDelete = (role: User) => {
    setAdminDelete(undefined);
  }
  
  const confirmDelete = async () => {
    if(!adminDelete) {
      return;
    }
    await deleteAdmin(adminDelete.id);
    fetchAdmins();
    setAdminDelete(undefined);
    addMessageSuccess('The user is now a regular user');
  }

  const actions = () => {
    return [{
      label: 'Edit',
      onClick: (admin: User) => setAdmin(admin),
      icon: <EditOutlined className="text-base-blue" />,
    }, {
      label: 'Delete',
      onClick: handleDelete,
      icon: <DeleteOutlineOutlined className="text-base-blue" />,
    }];
  }

  const colDef: Array<Object> = [
    { field: 'first_name', headerName: 'Name', flex: 1 },
    { field: 'last_name', headerName: 'Surname', flex: 1 },
    { field: 'email', flex: 1 },
    { field: 'admin_roles', headerName: 'Roles', flex: 1, cellRenderer: (data: any) => data.value.map((role: Role) => role.name).join(', ') },
    {
      field: 'id',
      headerName: 'Actions',
      maxWidth: 120,
      cellRenderer: (data: any) => <div className="flex space-x-2">
        {
          actions().map((action: any, index: number) => (
            <Tooltip key={index} title={action.label} arrow>
              <button onClick={() => action.onClick(data.data)}>
                {action.icon}
              </button>
            </Tooltip>
          ))
        }
      </div>
    },
  ];

  const onPaginationChange = (options: PaginationDataRequest) => {
    return fetchAdmins({ ...pagination, ...options });
  }

  const afterSaveCreate = () => {
    fetchAdmins({ ...pagination, currentPage: 1 });
    addMessageSuccess('New admin added');
  }

  const afterSaveUpdate = () => {
    fetchAdmins({ ...pagination, currentPage: 1 });
    setAdmin(undefined);
    addMessageSuccess('Admin updated');
  }

  return <PrivateLayout>
    <RoundedBox className="bg-white">
      <div className="mb-12">
        <Title>
          Add a New Admin
        </Title>
        <CreateSuperAdminForm afterSave={afterSaveCreate} />
      </div>
      <AgTable
        data={pagination.data}
        colDef={colDef}
        pagination={pagination}
        fetchMethod={onPaginationChange}
      />
    </RoundedBox>
    {
      admin && <Dialog
        buttons={[]}
        open={!!admin}
        onCancel={() => setAdmin(undefined)}
        title="Edit Admin Roles"
      >
        <EditSuperAdminForm admin={admin} afterSave={afterSaveUpdate} />
      </Dialog>
    }
    <DialogDelete
      open={adminDelete}
      cancel={handleCancelDelete}
      confirm={confirmDelete}
      title="Delete Admin"
    >
      Do you confirm to convert the user <b>{makeName(adminDelete)}</b> to a regular user?
    </DialogDelete>
  </PrivateLayout>
};

export default IndexSuperAdmin;