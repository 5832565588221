import React, { useState, useEffect } from 'react';
import { Button, Popover, FormControl, FormControlLabel, Checkbox, TextField, Collapse } from '@mui/material';
import { ExpandMore, Tune } from '@mui/icons-material';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import StatusChip from '../../table/StatusChip';

export type FilterType = 'statuses' | 'envelope' | 'caseNumber' | 'clientMatterNumber' | 'dateRange';

interface ReportFilterPanelProps {
  onChange?: (filters: any) => void;
  filters?: FilterType[];
  value: ReportFilter;
}

export interface ReportFilter {
  statuses?: string[];
  envelope?: string;
  caseNumber?: string;
  clientMatterNumber?: string;
  dateFrom?: dayjs.Dayjs | null;
  dateTo?: dayjs.Dayjs | null;
}

const emptyFilter: ReportFilter = {
  statuses: [],
  envelope: '',
  caseNumber: '',
  clientMatterNumber: '',
  dateFrom: null,
  dateTo: null
};

const allStatuses = {
  submitting: 'Submitting',
  failed: 'Failed',
  submitted: 'Submitted',
  accepted: 'Accepted',
  rejected: 'Rejected',
};

const baseFilters = [
  {
    label: 'Filing Status',
    key: 'statuses',
    renderElement: ({...props}) => <ReportFilterStatusInput {...props}/>,
    renderValue: ({...props}) => <ReportFilterStatusValue {...props}/>
  },
  {
    label: 'Date Range',
    key: 'dateRange',
    renderElement: ({...props}) => <ReportFilterDateInput {...props}/>,
    renderValue: ({...props}) => <ReportFilterDateValue {...props}/>
  },
  { label: 'Envelope N', key: 'envelope' },
  { label: 'Case Number', key: 'caseNumber' },
  { label: 'Client Matter Number', key: 'clientMatterNumber' }
];

const closedExpanded = {
  statuses: false,
  envelope: false,
  caseNumber: false,
  clientMatterNumber: false,
  dateRange: false
};

const areDifferent = (filter1: ReportFilter, filter2: ReportFilter) => {
  const statuses1 = [...(filter1.statuses || [])].sort().join('|');
  const statuses2 = [...(filter2.statuses || [])].sort().join('|');
  
  if (statuses1 !== statuses2) return true;
  
  if ((filter1.dateFrom?.format('YYYY-MM-DD') !== filter2.dateFrom?.format('YYYY-MM-DD')) ||
      (filter1.dateTo?.format('YYYY-MM-DD') !== filter2.dateTo?.format('YYYY-MM-DD'))) {
    return true;
  }

  const keys: (keyof ReportFilter)[] = ['envelope', 'caseNumber', 'clientMatterNumber'];
  return keys.some(key => filter1[key] !== filter2[key]);
};

const ReportFilterDefaultValue: React.FC = ({ value }: any) => value;

const ReportFilterDefaultElement: React.FC = ({ value, onChange }: any) => (
  <TextField
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="w-full"
    sx={{
      '& .MuiInputBase-input': {
        padding: '6px 8px'
      },
    }}
  />
);

const ReportFilterStatusValue: React.FC = ({ value }: any) => {
  if (!value?.length || value.length === Object.keys(allStatuses).length) return '';
  
  return (
    <div style={{fontSize: '70%'}}>
      {value.map((status: string) => (
        <span className='mr-1 relative' key={status} style={{top: '2px'}}>
          <StatusChip status={status} type='borderLess' />
        </span>
      ))}
    </div>
  );
};

const ReportFilterStatusInput: React.FC = ({ value, onChange }: any) => (
  <div className="flex flex-col gap-1 p-2">
    {Object.entries(allStatuses).map(([statusKey, status]) => (
      <FormControlLabel
        key={statusKey}
        style={{height: '30px'}}
        control={
          <Checkbox
            checked={value?.includes(statusKey)}
            onChange={(e) => {
              const statuses = value || [];
              onChange(e.target.checked 
                ? [...statuses, statusKey]
                : statuses.filter((st: string) => st !== statusKey)
              );
            }}
            color="primary"
            className='relative'
            style={{bottom: '2px'}}
          />
        }
        label={status}
      />
    ))}
  </div>
);

const ReportFilterDateValue: React.FC = ({ value }: any) => {
  if (!value?.dateFrom && !value?.dateTo) return '';
  
  const formatDate = (date: dayjs.Dayjs | null) => date?.format('MM/DD/YYYY') || '';
  return `${formatDate(value.dateFrom)} - ${formatDate(value.dateTo)}`;
};

const ReportFilterDateInput: React.FC = ({ value, onChange }: any) => (
  <div className="flex flex-col gap-4 p-2">
    <DatePicker
      label="From Date"
      value={value?.dateFrom}
      onChange={(newValue) => onChange({ ...value, dateFrom: newValue })}
      slotProps={{ textField: { size: 'small' } }}
    />
    <DatePicker
      label="To Date"
      value={value?.dateTo}
      onChange={(newValue) => onChange({ ...value, dateTo: newValue })}
      slotProps={{ textField: { size: 'small' } }}
    />
  </div>
);

const ReportFilterPanel: React.FC<ReportFilterPanelProps> = ({ value, onChange, filters }) => {
  const [valueOriginal, setValueOriginal] = useState<ReportFilter>({ ...emptyFilter });
  const [valueNew, setValueNew] = useState<ReportFilter>({ ...emptyFilter });
  const [expanded, setExpanded] = useState({ ...closedExpanded });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (!value) return;
    setValueOriginal({ ...value, statuses: [...(value.statuses || [])] });
    setValueNew({ ...value, statuses: [...(value.statuses || [])] });
  }, [value]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    if (areDifferent(valueOriginal, valueNew)) {
      onChange?.(valueNew);
      setValueOriginal(valueNew);
    }
  };

  const handleClearFilters = () => {
    setAnchorEl(null);
    setValueOriginal({ ...emptyFilter });
    setValueNew({ ...emptyFilter });
    if (areDifferent(valueOriginal, emptyFilter)) {
      onChange?.({ ...emptyFilter });
    }
  };

  const handleFieldChange = (key: string, value: any) => {
    setValueNew({ ...valueNew, [key]: value });
  };

  return (
    <>
      <Button
        endIcon={<Tune />}
        onClick={handlePopoverOpen}
        sx={{
          borderRadius: '50px',
          borderColor: '#5261A0',
          color: '#5261A0',
          padding: '6px 16px',
          textTransform: 'none',
          fontWeight: 'bold',
        }}
      >
        More Filters
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="p-8 w-[364px]">
          <div className="flex flex-row justify-between items-center mb-4">
            <span className="font-bold text-base">Filter By:</span>
            <Button
              onClick={handleClearFilters}
              variant="outlined"
              sx={{
                fontSize: '0.75rem',
                borderColor: '#3E4A7F',
                color: '#3E4A7F',
                borderRadius: '50px',
                textTransform: 'none',
              }}
            >
              Clear Filter
            </Button>
          </div>
          <div className="flex flex-col">
            {baseFilters
              .filter(({ key }) => !filters || filters.includes(key as FilterType))
              .map(({ label, key, renderElement = ReportFilterDefaultElement, renderValue = ReportFilterDefaultValue }) => (
                <FormControl fullWidth variant="outlined" key={key}>
                  <div
                    onClick={() => setExpanded({ ...closedExpanded, [key]: !expanded[key] })}
                    className="flex items-center justify-between cursor-pointer py-1 px-3"
                    style={{ minHeight: '50px' }}
                  >
                    <div className='flex flex-col'>
                      <span className="font-light text-sm">{label}</span>
                      <span className="font-light text-sm text-blue-900">
                        {renderValue({ value: valueNew[key] })}
                      </span>
                    </div>
                    <ExpandMore />
                  </div>
                  <Collapse in={expanded[key]}>
                    {renderElement({
                      value: valueNew[key],
                      onChange: (value: any) => handleFieldChange(key, value)
                    })}
                  </Collapse>
                </FormControl>
              ))}
          </div>
          <div className='flex flex-row justify-center'>
            <Button
              onClick={handlePopoverClose}
              variant="contained"
              sx={{
                backgroundColor: '#5261A0',
                color: 'white',
                borderRadius: '50px',
                textTransform: 'none',
                fontWeight: 'bold',
                padding: '6px 24px',
                marginTop: '16px',
                '&:hover': {
                  backgroundColor: '#3E4A7F',
                },
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default ReportFilterPanel;
