import { useAuth } from "./auth";
import { useCallback, useState } from "react";
import { HttpPaginationDataResponse, PaginationDataResponse, paginationEmpty, parseResponse } from "../components/table/AgTable";
import { permission } from "process";

// Updated User interface
export interface Permission {
  id?: number
  name: string
  description?: string
}

export interface Role {
  id?: number
  name: string
  description?: string
  permissions?: Permission[]
}

export interface RoleFilters extends PaginationDataResponse {
};

const useRoles = () => {
  const { axios } = useAuth({ middleware: 'auth' })
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [pagination, setPagination] = useState<RoleFilters>(paginationEmpty);

  const fetchRoles = useCallback(async (options?: RoleFilters) => {
    setLoading(true);
    setError(null);
    try {
      const params: any = {
        sort_by: "id_desc",
        items_per_page: options?.perPage || pagination.perPage,
        page: options?.currentPage || pagination.currentPage
      };
      delete params.currentPage

      const response: HttpPaginationDataResponse = (await axios.get('user-roles', {params})).data;

      setPagination({ ...pagination, ...options, ...parseResponse(response) });

      setLoading(false);
    } catch (err) {
      setError('Failed to fetch filings');
      setLoading(false);
    }
  }, []);

  const fetchRole = useCallback(async (id: number): Promise<Role> => {
    return (await axios.get(`user-roles/${id}`)).data;
  }, []);

  const deleteRole = useCallback(async (id: number): Promise<void> => {
    await axios.delete(`user-roles/${id}`);
  }, []);

  const saveRole = useCallback(async (role: Role): Promise<void> => {
    if(role.id) {
      await axios.put(`/user-roles/${role.id}`, role);
    } else {
      await axios.post(`/user-roles`, role);
    }
  }, []);

  const fetchPermissions = useCallback(async (): Promise<Permission[]> => {
    return (await axios.get('user-roles/permissions')).data;
  }, []);

  return {
    fetchRoles,
    fetchRole,
    saveRole,
    deleteRole,
    pagination,
    fetchPermissions,
  };
};

export default useRoles;
