import { ReactElement, useEffect } from "react";
import { useFormik } from "formik";
import { FormButton } from "../../../../components/forms/FormButton";
import useRoles, { Role } from "../../../../hooks/useRoles";
import * as yup from 'yup';
import useAdmins from "../../../../hooks/useAdmins";
import RoundedBox from "../../../../components/layout/RoundedBox";
import FormSelectMultiple from "../../../../components/forms/FormSelectMultiple";
import { makeName } from "../../../../utils/utils";
import useGlobalPaymentAccount from "../../../../hooks/useGlobalPaymentAccount";
import { Firm } from "../../../../hooks/useAccount";

interface SuperAdminFormProps {
  firm: Firm
  afterSave?: Function
}

const ConfigureFirmGlobalPaymentAccountsForm = ({ firm, afterSave }: SuperAdminFormProps): ReactElement => {
  const { editAdmin } = useAdmins();
  const { pagination, fetchGlobalPaymentAccounts, updateFirmGlobalPaymentAccounts } = useGlobalPaymentAccount();

  useEffect(() => {
    fetchGlobalPaymentAccounts({...pagination, currentPage: 1, perPage: 10000});
  }, []);

  useEffect(() => {
    const global_payment_accounts = (firm.global_payment_accounts ?? []).map((gpa: Role) => gpa.id);
    formik.setFieldValue('global_payment_accounts', global_payment_accounts)
  }, [firm]);

  const initialValues: any = {
    global_payment_accounts: []
  };

  const formik = useFormik({
    initialValues,
    validationSchema: () => {
      return yup.object().shape({
        // email: yup.string().required("Please enter the user email")
      });
    },
    onSubmit: async (values, { setSubmitting }) => {
      console.log(values)
      console.log('asdas')
      await updateFirmGlobalPaymentAccounts(firm, values);
      afterSave && afterSave();
    }
  });

  if(!firm) {
    return <></>
  }

  return <form onSubmit={formik.handleSubmit} className="text-sm max-w-2xl">
    <div className="mb-4">
      <div>
        <b>Firm: </b><span>{firm.name}</span>
      </div>
    </div>
    <RoundedBox className="bg-background-overlay-blue mb-8">
      <FormSelectMultiple options={pagination.data} formik={formik} name="global_payment_accounts" styles={{background: 'none', width: '500px', border: 'none'}}/>
    </RoundedBox>
    <div className="text-center">
      <FormButton className="w-56" type="submit">Save Changes</FormButton>
    </div>
  </form>
};

export default ConfigureFirmGlobalPaymentAccountsForm;