import { ReactElement, useEffect, useState } from "react";
import PrivateLayout from "../../../components/layout/PrivateLayout";
import RoundedBox from "../../../components/layout/RoundedBox";
import RoleForm from "./components/RoleForm";
import Title from "../../../components/Title";
import { useNavigate, useParams } from "react-router-dom";
import useRoles, { Role } from "../../../hooks/useRoles";
import BackArrow from "../../../components/BackArrow";

interface ManageSuperAdminProps {

}

const EditRole = ({ }: ManageSuperAdminProps): ReactElement => {
  
  const { fetchRole } = useRoles();
  const { id } = useParams();
  const [role, setRole] = useState<Role | undefined>();
  const navigate = useNavigate();
  
  useEffect(() => {
    if(!id) {
      return;
    }
    (async () => setRole(await fetchRole(parseInt(id))))()
  }, [id]);

  const afterSave = () => {
    navigate(`/admin-dashboard/access-control-roles`);
  }

  return <PrivateLayout>
    <RoundedBox className="bg-white">
      <BackArrow className="mb-4"/>
      <Title>
        Edit Role
      </Title>
      <RoleForm role={role} afterSave={afterSave} />
    </RoundedBox>
  </PrivateLayout>
};

export default EditRole;