import { useEffect, useState } from "react";
import { FormButton } from "../forms/FormButton";
import DialogCloseable from "./DialogCloseable";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import usePaymentAccounts from "../../hooks/usePaymentAccount";
import { useAuth } from "../../hooks/auth";

const firmAdmins = [{
    firstName: 'Jhon',
    lastName: 'Doe',
    phone: '(234) 111-5555',
    email: 'jhondoe@gmail.com'
  },
  {
    firstName: 'Jane',
    lastName: 'Doe',
    phone: '(234) 555-2312',
    email: 'janedoe@tyler.com'
  }];
  
interface DialongMissingPaymentAccountProp {
    setShowAddCourtPaymentAcc: Function;
    setShowAddMFRPaymentAcc: Function;
};

const DialogMissingPaymentAccount = ({setShowAddCourtPaymentAcc, setShowAddMFRPaymentAcc}:DialongMissingPaymentAccountProp) => {
    const [countTogaAccounts, setCountTogaAccounts] = useState(0);
    const [countStripeAccounts, setCountStripeAccounts] = useState(0);
    const [missingPaymentMethods, setMissingPaymentMethod] = useState(false);

    const {hasRole} = useAuth({middleware: 'auth'})
    // Account Dialog
    
    const {
      loading,
      getCountPaymentAccounts,
    } = usePaymentAccounts();
    
    const handleShowAddCourtPaymentAcc = () => {
      setShowAddCourtPaymentAcc(true);
      setMissingPaymentMethod(false);
    }

    const handleShowAddMFRPaymentAcc = () => {
      setShowAddMFRPaymentAcc(true);
      setMissingPaymentMethod(false);
    }

    const countPayments = async () => {
      const results = await getCountPaymentAccounts();
      setCountStripeAccounts(results.stripe);
      setCountTogaAccounts(results.toga);
      if(!hasRole(['admin'])) {
        // fetch admins, consultar
      };
  
      if(results?.stripe <= 0 || results?.toga <= 0)
        setMissingPaymentMethod(true);
      else 
        setMissingPaymentMethod(false);
    };
    
    useEffect(() => {
      countPayments();
    }, []);
  
   
    return (
        <DialogCloseable 
          width='600px'
          title="Hey there," 
          open={missingPaymentMethods}
          onClose={() => setMissingPaymentMethod(false)}
        >
          {hasRole(['admin', 'firm_admin']) ? (
            <>
              <p className="text-sm font-medium">There is no valid payment account associated with this firm, for the following</p>
              <ul className="list-disc pl-4 mb-2 text-sm font-medium">
                {countTogaAccounts <= 0 && <li>Court Fees <HelpOutlineIcon className="text-base-blue" sx={{ fontSize: 14 }} /></li>}
                {countStripeAccounts <= 0 &&<li>MFR Fees <HelpOutlineIcon className="text-base-blue" sx={{ fontSize: 14 }} /></li>}
              </ul>
              <p className="text-sm font-semibold">Please add a new payment account from the Admin Dashboard</p>
              {countTogaAccounts <= 0 && <FormButton onClick={handleShowAddCourtPaymentAcc} className="w-full text-center font-bold mt-6">Add new Court Fees Payment Account</FormButton>}
              {countStripeAccounts <= 0 && <FormButton onClick={handleShowAddMFRPaymentAcc} className="w-full text-center font-bold mt-6">Add new MFR Fees Payment Account</FormButton>}
            </>
          ) : (
            <>
              <p className="text-sm font-medium">There is no valid payment account associated with this firm, for the following</p>
              <ul className="list-disc pl-4 mb-2 text-sm font-medium">
                {countTogaAccounts <= 0 && <li>Court Fees <HelpOutlineIcon className="text-base-blue" sx={{ fontSize: 14 }} /></li>}
                {countStripeAccounts <= 0 &&<li>MFR Fees <HelpOutlineIcon className="text-base-blue" sx={{ fontSize: 14 }} /></li>}
              </ul>
              <p className="text-sm font-semibold mb-2">Please contact your firm admin and ask them to add a new payment account from the Admin Dashboard</p>
              <span className="text-sm font-semibold">Firm Admins:</span>
              <ul className="mb-4">
              {firmAdmins && firmAdmins.map((admin) => (
                <li className="text-sm font-medium">{`${admin.firstName} ${admin.lastName} - ${admin.phone} - ${admin.email}`}</li>
              ))}
              </ul>
              <p className="text-sm font-medium">All MFR features are accessible except for the fillings, until payment is updated</p>

            </>
          )}
          
        </DialogCloseable>
    );
}

export default DialogMissingPaymentAccount;