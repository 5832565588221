import React, { useState, useEffect } from "react";
import { Button, Typography, Box } from "@mui/material";
import CustomTable from "../../../components/table/CustomTable";
import AddPaymentDialog from "../../../components/dialogs/DialogCreatePaymentAccount";
import usePaymentAccounts, { PaymentAccount } from "../../../hooks/usePaymentAccount";
import ViewPaymentAccountDialog from "../../../components/dialogs/DialogViewPaymentAccount";
import { Visibility } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { selectPaymentAccounts } from "../../../components/assets/CaseWizard";
import { GridDeleteIcon } from "@mui/x-data-grid";

interface TableAccount extends PaymentAccount {
    actions?: Array<{
      label: string;
      onClick: (id: number) => void;
      icon: React.ReactNode;
    }>;
}

const CourtPaymentAccount = (): React.ReactElement => {
    // State management
    const [dialogOpen, setDialogOpen] = useState(false);
    const [viewDialogOpen, setViewDialogOpen] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState<PaymentAccount | null>(null);
    const paymentAccounts = useSelector(selectPaymentAccounts);

    // Hooks
    const {
        loading,
        fetchPaymentAccounts,
        createPaymentAccount,
        deletePaymentAccount,
        getPaymentAccountById,
        syncPaymentAccounts,
    } = usePaymentAccounts();

    // Fetch accounts on mount
    useEffect(() => {
        fetchPaymentAccounts();
    }, []);

    // Dialog handlers
    const handleDialogOpen = () => setDialogOpen(true);
    const handleDialogClose = () => setDialogOpen(false);
    const handleViewDialogClose = () => {
        setViewDialogOpen(false);
        setSelectedAccount(null);
    };

    // CRUD operations
    const handleCreateAccount = async (account: PaymentAccount) => {
        try {
        // await createPaymentAccount(account); //esto no va lo maneja el formulario internamente por el tema token etc..
        handleDialogClose();
        fetchPaymentAccounts(); // Refresh the list
        } catch (error) {
        console.error('Error creating account:', error);
        // You might want to show an error message to the user here
        }
    };

    const handleDeleteAccount = async (id: number) => {
        try {
        await deletePaymentAccount(id);
        fetchPaymentAccounts(); // Refresh the list
        } catch (error) {
        console.error('Error deleting account:', error);
        // You might want to show an error message to the user here
        }
    };

    const handleViewAccount = (id: number) => {
        const account = getPaymentAccountById(id);
        if (account) {
            setSelectedAccount(account);
            setViewDialogOpen(true);
        }
    };

    // Sync Payment Account Button
    const handleSyncPaymentAccounts = () => {
        syncPaymentAccounts();
    }

    // Table configuration
    const columns = [
        { 
        field: "name", 
        headerName: "Payment Account Name",
        flex: 1,
        },
        { 
        field: "type", 
        headerName: "Payment Account Type",
        flex: 1,
        valueGetter: (params: any) => {
            const typeMap = {
            'CC': 'Credit Card',
            'BankAccount': 'E-Checks',
            'WV': 'Waiver'
            };
            return typeMap[params.row.type] || params.row.type;
        }
        },
    ];

    // Process accounts for table display
    const processAccounts = (accounts: PaymentAccount[]): TableAccount[] => {
        return accounts.map((account) => ({
        ...account,
        id: account.id || Date.now(), // Ensure each row has an id
        actions: [
            {
            label: "View",
            onClick: () => handleViewAccount(account.id!),
            icon: <Visibility />,
            },
            {
            label: "Delete",
            onClick: () => handleDeleteAccount(account.id!),
            icon: <GridDeleteIcon />,
            },
        ],
        }));
    };

    // Filter accounts by global status
    const nonGlobalAccounts = paymentAccounts.filter((account) => !account.is_global);
    const globalAccounts = paymentAccounts.filter((account) => account.is_global);

    const processedNonGlobalAccounts = processAccounts(nonGlobalAccounts);
    const processedGlobalAccounts = processAccounts(globalAccounts);

    return (
        <>
            <Box display="flex" justifyContent="space-between" mb={2} mx={10}>
                <Button
                variant="contained"
                color="primary"
                sx={{
                    backgroundColor: "#5261A0",
                    textTransform: "capitalize",
                    fontSize: 14,
                    "&:hover": { backgroundColor: "#3e4d7a" },
                }}
                onClick={handleSyncPaymentAccounts}
                >
                Sync Payment Accounts
                </Button>
                <Button
                variant="contained"
                color="primary"
                sx={{
                    backgroundColor: "#5261A0",
                    textTransform: "capitalize",
                    fontSize: 14,
                    "&:hover": { backgroundColor: "#3e4d7a" },
                }}
                onClick={handleDialogOpen}
                >
                Add New Payment Account
                </Button>
            </Box>

            <Typography variant="h6" sx={{ fontSize: 16, marginBottom: 2 }}>
                Your Payment Accounts
            </Typography>
            <CustomTable
                rows={processedNonGlobalAccounts}
                columns={columns}
                loading={loading}
                variant="primary"
            />

            {/* <Typography variant="h6" sx={{ fontSize: 16, marginTop: 4, marginBottom: 2 }}>
                Global Payment Accounts
            </Typography>
            
            <CustomTable
                rows={processedGlobalAccounts}
                columns={columns}
                loading={loading}
                variant="primary"
            /> */}

            <AddPaymentDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                onSave={handleCreateAccount}
            />
            
            <ViewPaymentAccountDialog
                open={viewDialogOpen}
                onClose={handleViewDialogClose}
                account={selectedAccount}
            />
        </>
    );
}

export default CourtPaymentAccount;