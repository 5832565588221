import { ReactElement } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import LogInScreen from "../pages/auth/LogIn";
import RegisterScreen from "../pages/auth/Register";
import ForgotPasswordScreen from "../pages/auth/PasswordForgot";

import HomeScreen from "../pages/Home";
import StatisticsScreen from "../pages/Statistics";
import MyReportsScreen from "../pages/MyReports";
import MyEServicesScreen from "../pages/my-services/MyEServices";

import NoMatch from "../components/NoMatch";
import { Loader } from "../components/assets/Loader";
import { MessageBoard } from "../components/assets/MessageBoard";
import PasswordResetScreen from "../pages/auth/PasswordReset";
import FileToCourtScreen from "../pages/cases/FileToCourt";
import { FileCaseScreen } from "../pages/cases/FileCase";
import AdminDashboard from "../pages/admin-dashboard/AdminDashboardPage";
import EditUserPage from "../pages/admin-dashboard/edit-user/EditUserPage";
import CreateUserPage from "../pages/admin-dashboard/create-user/CreateUserPage";
import PaymentAccountsPage from "../pages/admin-dashboard/PaymentAccounts";
import AccountPage from "../pages/my-account/AccountPage";
import MyFilingsScreen from "../pages/my-filings/MyFilings";
import CaseDetails from "../pages/my-filings/details/CaseDetailsPage";
import EServiceDetails from "../pages/my-services/details/EServiceDetailsPage";
import EServiceDraftEditPage from "../pages/my-services/details/EServiceDraftEditPage";
import { FileIntoExistingCaseScreen } from "../pages/cases/FileIntoExistingCase";
import { EServiceCaseSearchResultScreen, FileIntoExistingCaseSearchResultScreen } from "../pages/cases/CaseSearchIndex";
import { EServiceCaseDetailsScreen, FileIntoExistingCaseDetailsScreen } from "../pages/cases/CaseDetails";
import { EServiceScreen } from "../pages/cases/EService";
import ReportDetails from "../pages/my-reports/details/ReportDetails";
import IndexRoles from "../pages/admin-privileges/access-control-roles/IndexRoles";
import EditRole from "../pages/admin-privileges/access-control-roles/EditRole";
import IndexSuperAdmin from "../pages/admin-privileges/manage-super-admins/IndexSuperAdmin";
import { useAuth } from "../hooks/auth";
import GlobalPaymentAccounts from "../pages/admin-privileges/global-payment-accounts/GlobalPaymentAccounts";

const RoutesWithAnimation = () => {
  const location = useLocation();
  const { hasPermission } = useAuth({ middleware: 'auth' })
  
  return (
    <>
      <Routes location={location} key={`routes-${location.key}`}>
        <Route path="/login" element={<LogInScreen />} />
        <Route path="/register" element={<RegisterScreen />} />
        <Route path="/forgot-password" element={<ForgotPasswordScreen />}  />
        <Route path="/reset-password" element={<PasswordResetScreen />} />
        <Route path="/statistics" element={<StatisticsScreen />} />
        <Route path="/my-filings" element={<MyFilingsScreen />} />
        <Route path="/my-filings/:id?" element={<CaseDetails />} />
        <Route path="/my-e-services" element={<MyEServicesScreen />} />
        <Route path="/received-service/:id?" element={<EServiceDetails />} />
        <Route path="/sent-service/:id?" element={<EServiceDetails />} />
        <Route path="/edit-draft-service/:id?" element={<EServiceDraftEditPage />} />
        <Route path="/my-reports" element={<MyReportsScreen />} />
        <Route path="/my-reports/:id?" element={<ReportDetails />} />
        <Route path="/file-to-court" element={<FileToCourtScreen />} />
        <Route path="/file-case/:id?" element={<FileCaseScreen />} />
        <Route path="/file-into-existing-case/search" element={<FileIntoExistingCaseSearchResultScreen />} />
        <Route path="/file-into-existing-case/case-details/:id/:location_id?" element={<FileIntoExistingCaseDetailsScreen />} />
        <Route path="/file-into-existing-case/:id" element={<FileIntoExistingCaseScreen />} />
        <Route path="/e-service/search" element={<EServiceCaseSearchResultScreen />} />
        <Route path="/e-service/case-details/:id/:location_id?" element={<EServiceCaseDetailsScreen />} />
        <Route path="/e-service/:id" element={<EServiceScreen />} />
        <Route path="/admin-dashboard/user-management" element={<AdminDashboard />} />
        <Route path="/admin-dashboard/user-management/edit-user/:id?" element={<EditUserPage />} />
        <Route path="/admin-dashboard/user-management/create-user" element={<CreateUserPage />} />
        <Route path="/admin-dashboard/payment-accounts" element={<PaymentAccountsPage />} />
        <Route path="/my-account" element={<AccountPage />} />

        { hasPermission('manage-super-admins') && <Route path="/admin-dashboard/manage-super-admins" element={<IndexSuperAdmin />} /> }
        { hasPermission('access-control-roles') && <Route path="/admin-dashboard/access-control-roles" element={<IndexRoles />} /> }
        { hasPermission('access-control-roles') && <Route path="/admin-dashboard/access-control-roles/:id" element={<EditRole />} /> }
        { hasPermission('global-payment-accounts') && <Route path="/admin-dashboard/global-payment-accounts" element={<GlobalPaymentAccounts />} /> }

        <Route path="/" element={<HomeScreen />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  );
};

const AppRouter = (): ReactElement => {
  return (
    <BrowserRouter>
      <RoutesWithAnimation />
      <MessageBoard />
      <Loader />
    </BrowserRouter>
  );
};

export default AppRouter;
