
import { EditOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useFilings from '../../hooks/useFilings';
import FilterSearchBar from '../../components/FilterSearchBar';
import { DateRange, DateRangeInput } from '../../components/Inputs/DateRangeInput';
import AgTable, { PaginationDataRequest } from '../../components/table/AgTable';
import { FilingsFilter } from '../../components/Inputs/FilingsFilterInput';
import RoundedBox from '../../components/layout/RoundedBox';
import ReportFilterPanel from '../../components/reports/filters/ReportFilterPanel';

// TODO: delete mockData and replace with real data
const mockData = Array(10).fill(null).map((_, index) => ({
  id: index + 1,
  filing_case: {
    filing_case_envelope: { 
      envelope_id: '897'
    },
    docket_id: 'ATC-93021',
    title: 'Johnson vs. Smith',
    filing_status: { code: 'pending' }
  },
  court_location: 'Bexar County',
  client_number: 'CMT-2024-6789',
  firm_name: 'Kuliza Tech.'
}));

const MyReports = () => {
  const { pagination, fetchCaseMyFilings } = useFilings();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCaseMyFilings();
  }, []);

  // TODO: export ..?
  const handleExport = () => {
    console.log('handleExport');
  }

  const handleViewReport = (report: any) => {
    console.log('View Report', report.id);
    navigate(`/my-reports/${report.id}`);
  }

  const actions = () => {
    const baseActions = [
      {
        label: 'View',
        onClick: handleViewReport,
        icon: <VisibilityOutlined className="text-base-blue" />,
      },
    ];

    // add more actions here
    return baseActions;
  };
  
  //TODO: Use corresponding field names
  const colDef: Array<Object> = [
    { field: 'filing_case.filing_case_envelope.envelope_id', headerName: 'Envelope N', flex: 1 },
    { field: 'filing_case.docket_id', headerName: 'Case N', flex: 1 },
    { field: 'filing_case.title', headerName: 'Case Title', flex: 1 },
    { field: 'court_location', headerName: 'Court Location', flex: 1 },
    { field: 'client_number', headerName: 'Client Matter N', flex: 1 },
    {
      field: 'filing_case.filing_status.code',
      headerName: 'Actions',
      maxWidth: 120,
      cellRenderer: (data: any) => <div className="flex space-x-2">
        {actions().map((action: any, index: number) => (
          <Tooltip key={index} title={action.label} arrow>
            <button onClick={() => action.onClick(data.data)}>
              {action.icon}
            </button>
          </Tooltip>
        ))}
      </div>
    },
  ];

  const onSearchChange = (search: string) => {
    return fetchCaseMyFilings({...pagination, search, currentPage: 1});
  }
  
  const onDateChange = (dateRange: DateRange) => {
    return fetchCaseMyFilings({...pagination, ...dateRange, currentPage: 1});
  }

  const onFilterChange = (filters: FilingsFilter) => {
    return fetchCaseMyFilings({...pagination, ...filters, currentPage: 1});
  }

  const onPaginationChange = (options: PaginationDataRequest) => {
    return fetchCaseMyFilings({...pagination, ...options});
  }

  return <RoundedBox className= "bg-white">
    <div className="flex flex-col space-y-4 mt-8">
      <FilterSearchBar onChange={onSearchChange} placeholder="Search by Client Matter N, Case N, Case Title, or Court Location..."/>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <DateRangeInput onChange={onDateChange} value={pagination} />
            {/* <FilingsFilterInput onChange={onFilterChange} value={pagination}/> */}
            <ReportFilterPanel onChange={onFilterChange} value={pagination} />
          </div>
        </div>
      </LocalizationProvider>
    </div>
    <div className="flex justify-end mb-4">
      <Button
        variant='contained'
        color="primary"
        sx={{
          backgroundColor: "#5261A0",
          width: 196,
          height: 44,
          textTransform: "capitalize",
          fontSize: 14,
          padding: "8px 25px",
          "&:hover": { backgroundColor: "#3E4D7A" }
        }}
        onClick={handleExport}
      >
        Export
      </Button>
    </div>
    <div className="mb-8 mt-16">
      <AgTable
        // data={pagination.data}
        data={mockData}
        colDef={colDef}
        pagination={pagination}
        fetchMethod={onPaginationChange}
      />
    </div>
  </RoundedBox>
};

export default MyReports;
