import { VisibilityOutlined } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useFilings from '../../../hooks/useFilings';
import RoundedBox from '../../../components/layout/RoundedBox';
import AgTable, { PaginationDataRequest } from '../../../components/table/AgTable';
import dayjs from 'dayjs';
import StatusChip from '../../../components/table/StatusChip';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRange, DateRangeInput } from '../../../components/Inputs/DateRangeInput';
import FilingsFilterInput, { FilingsFilter } from '../../../components/Inputs/FilingsFilterInput';
import FilterSearchBar from '../../../components/FilterSearchBar';
import { dateHour } from '../../../utils/utils';

interface FilingToDelete {
  filingId: string;
  caseId: string;
}

const DraftFilingsTab = () => {
  const { pagination, fetchEServiceReceivedFilings } = useFilings();
  const navigate = useNavigate();

  // Fetch draft filings whenever filters change
  useEffect(() => {
    fetchEServiceReceivedFilings();
  }, []);

  const handleViewFiling = (data: any) => {
    navigate(`/received-e-service/${data.filing_case.id}`);
  };

  const actions = (status: any) => {
    const baseActions = [
      {
        label: 'View',
        onClick: handleViewFiling,
        icon: <VisibilityOutlined className="text-base-blue" />,
      }
    ];

    return baseActions;
  };

  const colDef: Array<Object> = [
    { field: 'filing_case.filing_case_envelope.envelope_id', headerName: 'Envelope N', flex: 1 },
    { field: 'filing_case.docket_id', headerName: 'Case N', flex: 1 },
    { field: 'client_number', headerName: 'Client Matter N', flex: 1 },
    {
      field: 'filing_case.filing_status',
      headerName: 'Filing Status',
      cellRenderer: (data: any) => <StatusChip status={data.value} type='borderLess' />
    },
    {
      field: 'updated_at',
      headerName: 'Last Update',
      valueFormatter: (params: any) => dateHour(params.value)
    },
    {
      field: 'filing_case.filing_status.code',
      headerName: 'Actions',
      maxWidth: 120,
      cellRenderer: (data: any) => (
        <div className="flex space-x-2">
          {actions(data.value).map((action: any, index: number) => {
            return (
              <Tooltip key={index} title={action.label} arrow>
                <button onClick={() => action.onClick(data.data)}>
                  {action.icon}
                </button>
              </Tooltip>);
          })}
        </div>
      )
    },
  ];

  const onSearchChange = (search: string) => {
    return fetchEServiceReceivedFilings({ ...pagination, currentPage: 1, search });
  }

  const onDateChange = (dateRange: DateRange) => {
    return fetchEServiceReceivedFilings({ ...pagination, ...dateRange, currentPage: 1 });
  }

  const onFilterChange = (filters: FilingsFilter) => {
    return fetchEServiceReceivedFilings({ ...pagination, ...filters, currentPage: 1 });
  }

  const onPaginationChange = (options: PaginationDataRequest) => {
    return fetchEServiceReceivedFilings({ ...pagination, ...options });
  }

  return <>
    <RoundedBox className="px-10 py-6 border-2 border-base-blue rounded-2xl mb-8">
      <Typography className="text-black text-center">
        Users and Attorneys can only view Received E-Services after a successful filing submission from their respective MyFileRunner Account.
      </Typography>
    </RoundedBox>
    <div className="flex flex-col space-y-4 mb-8">
      <FilterSearchBar onChange={onSearchChange} placeholder="Search by Client Matter N, Case N, Case Title, or Court Location..."/>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <DateRangeInput onChange={onDateChange} value={pagination} />
            <FilingsFilterInput onChange={onFilterChange} value={pagination} />
          </div>
        </div>
      </LocalizationProvider>
    </div>
    <div className="mb-8">
      <AgTable
        data={pagination.data}
        colDef={colDef}
        pagination={pagination}
        fetchMethod={onPaginationChange}
      />
    </div>
  </>
};

export default DraftFilingsTab;
