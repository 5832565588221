import { ReactElement, useEffect, useState } from "react";
import PrivateLayout from "../../../components/layout/PrivateLayout";
import RoundedBox from "../../../components/layout/RoundedBox";
import RoleForm from "./components/RoleForm";
import Title from "../../../components/Title";
import AgTable, { PaginationDataRequest } from "../../../components/table/AgTable";
import useRoles, { Role } from "../../../hooks/useRoles";
import { Tooltip } from "@mui/material";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import DialogDelete from "../../../components/dialogs/DialogDelete";
import { useNavigate } from "react-router-dom";
import { useMessageBoard } from "../../../components/assets/MessageBoard";

interface ManageSuperAdminProps {

}

const IndexRoles = ({ }: ManageSuperAdminProps): ReactElement => {

  const { fetchRoles, pagination, deleteRole } = useRoles();
  const [role, setRole] = useState<Role>();
  const [roleDelete, setRoleDelete] = useState<Role>();
  const navigate = useNavigate();
  const { addMessageSuccess, addMessageError } = useMessageBoard();

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleEdit = (role: Role) => {
    navigate(`/admin-dashboard/access-control-roles/${role.id}`);
  }

  const handleDelete = (role: Role) => {
    setRoleDelete(role);
  }

  const handleCancelDelete = (role: Role) => {
    setRoleDelete(undefined);
  }
  
  const confirmDelete = async () => {
    if(!roleDelete) {
      return;
    }
    await deleteRole(roleDelete.id);
    fetchRoles();
    setRoleDelete(undefined);
  }

  const actions = (role: Role) => {
    return [{
      label: 'Edit',
      onClick: handleEdit,
      icon: <EditOutlined className="text-base-blue" />,
    }, {
      label: 'Delete',
      onClick: handleDelete,
      icon: <DeleteOutlineOutlined className="text-base-blue" />,
    }];
  }

  const colDef: Array<Object> = [
    { field: 'name', headerName: 'Role name', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 1 },
    {
      field: 'id',
      headerName: 'Actions',
      maxWidth: 120,
      cellRenderer: (data: any) => <div className="flex space-x-2">
        {
          actions(data.value).map((action: any, index: number) => (
            <Tooltip key={index} title={action.label} arrow>
              <button onClick={() => action.onClick(data.data)}>
                {action.icon}
              </button>
            </Tooltip>
          ))
        }
      </div>
    },
  ];

  const onPaginationChange = (options: PaginationDataRequest) => {
    return fetchRoles({ ...pagination, ...options });
  }

  const afterSave = () => {
    setRole(undefined)
    fetchRoles({ ...pagination, currentPage: 1 });
    addMessageSuccess('New role added');
  }

  return <PrivateLayout>
    <RoundedBox className="bg-white">
      <div className="mb-12">
        <Title>
          Add New Role
        </Title>
        <RoleForm afterSave={afterSave} />
      </div>
      <AgTable
        data={pagination.data}
        colDef={colDef}
        pagination={pagination}
        fetchMethod={onPaginationChange}
      />
    </RoundedBox>
    <DialogDelete
      open={roleDelete}
      cancel={handleCancelDelete}
      confirm={confirmDelete}
      title="Delete Role"
    >
      Do you confirm to delete the role <b>{roleDelete?.name}</b>
    </DialogDelete>
  </PrivateLayout>
};

export default IndexRoles;