


import {loadStripe} from '@stripe/stripe-js';
import { useFormik } from "formik";
import * as yup from 'yup';
import StripeNewCardForm from "./stripe/StripeNewCardForm";
import { Elements } from '@stripe/react-stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

interface AddCardDetailProps {
    onCancel: Function;
    onCreate: Function;
}
const key = process.env.REACT_APP_STRIPE_PUBLIC || '';
const stripePromise = loadStripe(key);

const AddStripeCardDetailForm = ({onCancel, onCreate}: AddCardDetailProps) => {
    
    const handleSubmit = (data: any) => {
        onCreate(data);
    };
  
    const handleCancel = () => {
        onCancel();
    };

    return (
        <Elements stripe={stripePromise} /*options={options} */>
            <StripeNewCardForm onCancel={handleCancel} onCreate={handleSubmit} />
        </Elements>
    );
}

export default AddStripeCardDetailForm;