import { useCallback, useState } from "react";
import { useAuth } from "./auth";

const useStripe = () => { 
  const {axios} = useAuth({middleware: 'auth'})
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Set StripeJS 
  const createCustomer = useCallback(async (data: any) => {
    setLoading(true);
    setError(null);
    try {
      const response: any = (await axios.post('stripe/customer', data)).data;
      setLoading(false);
      return response;
    } catch (err) {
      setError('Failed to fetch payment method');
      setLoading(false);
    }
  }, []);

  const fetchPaymentMethod = useCallback(async (id: string) => {
    setLoading(true);
    setError(null);
    try {
      const response: any = (await axios.get('/payment-accounts', {
        params: {
          per_page,
          page,
          is_global,
          mfr_type: type
        },
      })).data;

      setLoading(false);
      return response;
    } catch (err) {
      setError('Failed to fetch payment method');
      setLoading(false);
    }
  }, []);

  const createPaymentMethod = useCallback(async (data: any) => {
    setLoading(true);
    setError(null);
    try {
      const response: any = (await axios.post('stripe/payment-method', data)).data;
      setLoading(false);
      return response;
    } catch (err) {
      setError('Failed to fetch payment method');
      setLoading(false);
    }
  }, []);

  const attachPaymentMethod = useCallback(async (customer_id: string, payment_method_id: string) => {
    setLoading(true);
    setError(null);
    try {
        const response: any = (await axios.post('stripe/attach-payment-method', {
            customer_id,
            payment_method_id})
        ).data;
    
        setLoading(false);
        return response;
    } catch (err) {
      setError('Failed to fetch payment method');
      setLoading(false);
    }
  }, []);

  const createPaymentIntent = useCallback(async (data: any) => {
    setLoading(true);
    setError(null);
    try {
      const response: any = (await axios.post('stripe/payment-intent', data)).data;
      setLoading(false);
      return response;
    } catch (err) {
      setError('Failed to fetch payment method');
      setLoading(false);
    }
  }, []);

  const confirmPaymentIntent = useCallback(async (data: any) => {
    setLoading(true);
    setError(null);
    try {
      const response: any = (await axios.post('stripe/payment-intent/confirm', data)).data;
      setLoading(false);
      return response;
    } catch (err) {
      setError('Failed to fetch payment method');
      setLoading(false);
    }
  }, []);

  // useEffect(() => {
  //   fetchFilings();
  // }, [fetchFilings]);

  return {
    createCustomer,
    createPaymentMethod,
    attachPaymentMethod,
    fetchPaymentMethod,
    createPaymentIntent,
    confirmPaymentIntent,
    loading,
    error
  };
};

export default useStripe;