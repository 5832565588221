import { ReactElement, RefObject, useEffect, useMemo, useRef, useState } from "react";
import FormLabel from "../../../../components/forms/FormLabel";
import FormInput from "../../../../components/forms/FormInput";
import { useFormik } from "formik";
import { FormButton } from "../../../../components/forms/FormButton";
import FormTextarea from "../../../../components/forms/FormTextarea";
import useRoles, { Permission, Role } from "../../../../hooks/useRoles";
import { AgGridReact } from "ag-grid-react";
import { SizeColumnsToContentStrategy, SizeColumnsToFitGridStrategy, SizeColumnsToFitProvidedWidthStrategy } from "ag-grid-community";
import Title from "../../../../components/Title";
import * as yup from 'yup';
import { Checkbox } from "@mui/material";

interface RoleFormProps {
  role?: Role
  afterSave?: Function
}

const RoleForm = ({ role, afterSave }: RoleFormProps): ReactElement => {
  const gridRef: RefObject<AgGridReact> = useRef<AgGridReact>(null);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const { fetchPermissions, saveRole } = useRoles();

  useEffect(() => {
    if (role) {
      formik.setValues(role);
      (async () => setPermissions(await fetchPermissions()))()
    } else {
      formik.setValues(initialValues)
    }
  }, [role]);

  useEffect(() => {
  }, [role?.id]);

  const initialValues: any = {
    name: '',
    description: '',
    permissions: []
  };

  const formik = useFormik({
    initialValues,
    validationSchema: () => {
      return yup.object().shape({
        name: yup.string().required("Please enter a role name")
      });
    },
    onSubmit: async (values, { setSubmitting }) => {
      values = {...values, permissions: values.permissions.map((permission: Permission) => permission.id)};
      const response = await saveRole(values);
      formik.resetForm();
      afterSave && afterSave();
    }
  });

  const autoSizeStrategy = useMemo<
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy
  >(() => {
    return {
      type: "fitGridWidth",
    };
  }, []);

  const handleCheckboxChange = (value: any, permission: Permission) => {
    let permissions = formik.values.permissions;
    if(value.target.checked) {
      permissions.push(permission);
    } else {
      permissions = permissions.filter((perm: Permission) => permission.id != perm.id);
    }
    formik.setFieldValue('permissions', permissions);
  }

  const renderCheckbox = ({data}: any) => {
    const permission = data;
    return <Checkbox
      checked={!!formik.values.permissions?.find((perm: Permission) => permission.id == perm.id)}
      onChange={(e) => handleCheckboxChange(e, permission)}
      name="submitted"
      sx={{
        color: "#7C7B84",
        "&.Mui-checked": {
          color: "#5261A0",
        },
        paddingTop: 0
      }}
    />
  }

  const colDef = [
    { field: "actions", headerName: "Enable", width: 80, cellRenderer: renderCheckbox },
    { field: "name", headerName: "Name" },
    { field: "description", headerName: "Description", flex: 1 },
  ];

  return <form onSubmit={formik.handleSubmit} className="text-sm">
    <div className="flex">
      <div className="flex-1">
        <div className="max-w-2xl w-full">
          <div className="mb-6">
            <FormLabel mandatory>
              Name
            </FormLabel>
            <FormInput
              formik={formik}
              name="name"
            />
          </div>
          <div className="mb-6">
            <FormLabel mandatory>
              Description
            </FormLabel>
            <FormTextarea
              formik={formik}
              name="description"
            />
          </div>
          {
            !formik.values.id && <div className="text-center">
              <FormButton className="m-auto w-full">
                Add New Role
              </FormButton>
            </div>
          }
        </div>
      </div>
      <div className="flex-1 text-right">
        {
          formik.values.id && <div className="pr-24">
            <FormButton className="w-48 mt-16">
              Edit Role
            </FormButton>
          </div>
        }
      </div>
    </div>
    {
      formik.values.id && <div>
        <Title>Role Permissions</Title>
        <AgGridReact
          ref={gridRef}
          rowData={permissions}
          columnDefs={colDef}
          autoSizeStrategy={autoSizeStrategy}
          pagination={false}
          suppressPaginationPanel={true}
          domLayout="autoHeight"
          onGridReady={() => gridRef.current?.api.sizeColumnsToFit()}
        />
      </div>
    }
  </form>
};

export default RoleForm;