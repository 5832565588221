import * as MUIDialog from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const Dialog = ({ children, title, buttons, open=false, onCancel, ...props }: any): ReactElement => {

  const [localOpen, setLocalOpen] = useState(true);
  const [dButtons, setDButtons]: [{text: string, action: Function, sx?: any}[], any] = useState([]);

  const handleClose = () => {
    setLocalOpen(false)
    if(onCancel) {
      onCancel()
      setLocalOpen(true)
    }
  }

  useEffect(() => {
    if(!buttons) {
      buttons = [{
        text: 'Close',
        action: handleClose
      }];
    }
    buttons = buttons.map((button: any) => ({...button, sx: {fontWeight: 'bold', ...(button.sx ?? {})}}));
    setDButtons(buttons);
  }, [buttons]);

  return <MUIDialog.Dialog open={open && localOpen} {...props}>
    <div className="p-4">
      <div className="flex pb-4">
        <div className="flex-1">
          {
            title && <div className="text-xl font-semibold flex-1">
              {title}
            </div>
          }
        </div>
        <div className="flex-initial">
          {
            onCancel && <MUIDialog.IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </MUIDialog.IconButton>
          }
        </div>
      </div>
      <div className="p-2" style={{minHeight: '100px'}}>
        {children}
      </div>
      <MUIDialog.DialogActions>
        {
          dButtons && dButtons.map((button, key) => <MUIDialog.Button
            onClick={(e) => button.action(e)}
            key={`d-button-${key}`}
            sx={button.sx ?? {} }
            className="text-red-600"
          >
            {button.text}
          </MUIDialog.Button>)
        }
      </MUIDialog.DialogActions>
    </div>
  </MUIDialog.Dialog>
}
export default Dialog;